import React, { useEffect, useRef, useState } from 'react';

function TableInputField({
  className, dataTh, fieldName, placeholder, transaction, onUpdateTransaction,
  numberField, editable, errors, displayValue, preview, onKeyDown = () => { },
}) {
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  const updateTransaction = (e) => {
    const { target, target: { value } } = e;
    const newTranscation = transaction;
    if (numberField && value !== '-') {
      const newValue = parseFloat(value).toFixed(2);
      newTranscation[fieldName] = Number.isNaN(Number(newValue)) ? parseFloat(0).toFixed(2) : newValue;
    } else {
      newTranscation[fieldName] = value;
    }

    const position = target.selectionStart;

    onUpdateTransaction(newTranscation, () => {
      target.selectionEnd = position;
    });
  };

  const onBlur = () => { setActive(false); };
  const onFocus = () => { setActive(true); };

  useEffect(() => {
    active && inputRef.current.select();
  }, [active]);

  const hasErrorClass = errors && errors[fieldName] ? 'has-error' : '';

  return (
    <td className={`${className} ${hasErrorClass} ${preview ? 'has-preview' : ''} `} data-th={dataTh}>
      <span>
        <textarea
          ref={inputRef}
          type="text"
          name={fieldName}
          className={`input-table input-r ${preview ? 'to-preview' : ''}`}
          placeholder={placeholder}
          value={(!active && displayValue) ? displayValue : transaction[fieldName]}
          onChange={updateTransaction}
          readOnly={!editable}
          disabled={!editable}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        {preview && ((!active && displayValue) || transaction[fieldName])
          ? (<div className="preview">{(!active && displayValue) ? displayValue : transaction[fieldName]}</div>) : null
        }
        {hasErrorClass
          ? (
            <div className="error">
              {errors[fieldName].map(er => (
                <div key={er} className="hint error">{er}</div>
              ))}
            </div>
          ) : null
        }
      </span>
    </td>
  );
}

export default TableInputField;
