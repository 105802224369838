import Model from './model';
import SupplierModel from './supplier_model';

class SupplierItemModel extends Model {
  constructor({ supplier, ...attributes }) {
    super(attributes);
    this.supplier = new SupplierModel(supplier);
    this.bulkActionsSelected = false;
  }

  changedBulkAction = () => {
    this.bulkActionsSelected = !this.bulkActionsSelected;
    return this;
  }

  selectAllBulkAction = (value) => {
    this.bulkActionsSelected = !value;
    return this;
  }
}

export default SupplierItemModel;
