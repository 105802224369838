import React from 'react';
import DateFilterInput from '@/components/accountings/shared/date_filter_input';
import Search from '@/components/accountings/shared/search';
import ExportButton from '@/components/shared/buttons/export_button';
import FilterDropdown from '@/components/common/filters/filter_dropdown';

const FiltersAndSearch = ({
  filterActions, filters, onDateFilter, clearDate,
  onFilter, onRemoveFilter, onQueryFilter, currentFilters,
  showTransactions, onShowTransactions,
}) => {
  const dateFrom = currentFilters.date_from;
  const dateTo = currentFilters.date_to;

  const checkedClass = showTransactions ? 'checked' : '';

  const exportUrl = format => `${window.location.origin}/api/accountings/vouchers.${format}${window.location.search}`;

  return (
    <div className="table-actions">
      <div className="filters t-under-hide">
        {filterActions.map(actionName => (
          <FilterDropdown
            key={actionName}
            actionName={actionName}
            filters={filters}
            onFilter={onFilter}
            onRemoveFilter={onRemoveFilter}
          />
        ))}
        <DateFilterInput
          onDateFilter={onDateFilter}
          onRemoveFilter={onRemoveFilter}
          clearDate={clearDate}
          currentFilters={currentFilters}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
      <div className="right-actions">
        <div className="items center">
          <div className="control">
            <label className="has-checkbox">
              <span
                className={`checkbox input-s radio ${checkedClass}`}
                onClick={onShowTransactions}
              />
              <span className="label" onClick={onShowTransactions}>
                {I18n.t('invoices.filters.show_transactions')}
              </span>
            </label>
          </div>
          <Search onQueryFilter={onQueryFilter} currentFilters={currentFilters} />
          <div className="control">
            <ExportButton exportUrl={exportUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersAndSearch;
