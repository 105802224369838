import React, { useState } from 'react';
import InternalFileModal from './internalFileModal';
import VoucherFile from './voucherFile';

export default function InternalFiles({
  voucher, currentUser, updateVoucher, toggleSideBarOpen,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="form-section auto">
      <div className="form-section-header not-desktop">
        <span>{I18n.t('purchase_orders.form.documents')}</span>
      </div>
      <div className="form-section-content">
        <div className="tabs">
          <div className="tab-navs">
            <a className="sidebar-toggle" onClick={toggleSideBarOpen}>
              <i className="icon-close" />
            </a>
            <div className="tab-labels grid">
              <div className="ct-p-4 ct-text-sm ct-font-medium">{I18n.t('purchase_orders.form.internal_files')}</div>
              <div />
            </div>
          </div>
          <div className="">
            <div>
              <ul className="items menu upload-items scroll">
                {voucher.files.map(file => (
                  <VoucherFile key={file.id} file={file} voucher={voucher} updateVoucher={updateVoucher} currentUser={currentUser} />
                ))}
              </ul>
              <div className="text-right ct-px-4 ct-py-3 ct-text-xs">
                <label className="link" onClick={() => setModalOpen(true)}>
                  {I18n.t('purchase_orders.form.upload_internal_file')}
                </label>
              </div>
            </div>
          </div>
          <InternalFileModal
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
            voucher={voucher}
            updateVoucher={updateVoucher}
          />
        </div>
      </div>
    </div>
  );
}
