import React from 'react';
import PropTypes from 'prop-types';
import BigDecimal from 'js-big-decimal';
import ItemError from './item_error';
import DragItemHandle from './drag_item_handle';
import Receive from './receive';
import NumberConverter from '../../shared/number_converter';
import Textarea from '../../shared/textarea';
import SupplierItemsModalService from '../../../services/supplier_items_modal_service';
import SupplierItemModel from '../../../models/supplier_item_model';

class OrderItem extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    uuid: PropTypes.string,
    quantity: PropTypes.number,
    unit_measure_quantity: PropTypes.number,
    unit_measure_price: PropTypes.number,
    price: PropTypes.number,
    category_id: PropTypes.number,
    unit: PropTypes.string,
    sku: PropTypes.string,
    received: PropTypes.bool,
    received_quantity: PropTypes.number,
    _destroy: PropTypes.number,
    mounted: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      id: props.item.id,
      uuid: props.item.uuid,
      name: props.item.name || '',
      price: props.item.price == 0 ? '' : parseFloat(props.item.price),
      quantity: props.item.quantity == null ? '' : props.item.quantity === '' ? props.item.quantity : parseFloat(props.item.quantity).toFixed(2),
      unit_measure_quantity: props.item.unit_measure_quantity,
      unit_measure_price: props.item.unit_measure_price,
      category_id: props.item.category_id,
      unit: props.item.unit || '',
      sku: props.item.sku || '',
      received: props.item.received || '',
      received_quantity: props.item.received_quantity || '',
      pass: props.item.pass,
      fail: props.item.fail,
      total: 0,
      total_unit: 0.0,
      additional: 0,
      _destroy: 0,
      mounted: props.item.mounted ? props.item.mounted : false,
      supplierItems: [],
      e_type: 'item',
      position: props.item.position,
      stateCategories: props.categories,
      focus: props.item.focus || false,
    };
  }

  componentDidMount() {
    this.setTotalPrice(true);
  }

  componentDidUpdate(prevProps) {
    const { categories, calculationMethod } = this.props;
    if (prevProps.categories !== categories) {
      this.updateStateCategories(categories);
    }
    if (prevProps.calculationMethod !== calculationMethod) {
      this.setTotalPrice(true);
    }
  }

  onChangeItemNumber = (e) => {
    const { purchaseOrderSupplierId } = this.props;

    if (e.target.value !== '') {
      const finalQuery = `?supplier_id[]=${purchaseOrderSupplierId}&by_number=${e.target.value}`;
      SupplierItemsModalService.index(finalQuery)
        .then(({ supplier_items: items }) => {
          this.setState({
            supplierItems: items.map(item => new SupplierItemModel(item)),
          });
        });
    } else {
      this.setState({ supplierItems: [] });
    }

    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.setTotalPrice(true); });
  }

  onClick = (item) => {
    this.setState({
      sku: item.number,
      name: item.description,
      price: (item.grossPrice * (100 - item.discount)) / 100,
      quantity: item.minimumOrderQuantity,
      unit: item.unitMeasure,
    }, () => { this.setTotalPrice(true); });
  }

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.setTotalPrice(true); });
  }

  onCategoryChange = async (value) => {
    await this.onChange({ target: { name: 'category_id', value } });
    // await this.escapeEdit();
  }

  onNumberChange = (e) => {
    let { target: { value } } = e;
    const { target } = e;
    const { state } = this;
    if (value === '' || value === undefined || value === null) {
      value = '';
    } else {
      value = parseFloat(value).toFixed(2);
      value = NumberConverter(value) || state[e.target.name];
    }

    const position = target.selectionStart;

    this.setState({
      [e.target.name]: value,
    }, () => {
      target.selectionEnd = position;

      this.setTotalPrice(true);
    });
  }

  onUnitMeasurePriceChange = (e) => {
    let { target: { value } } = e;
    const { target } = e;
    const { state } = this;
    if (value === '') {
      value = undefined;
    } else {
      value = parseFloat(value).toFixed(2);
      value = NumberConverter(value) || state[e.target.name];
    }

    const position = target.selectionStart;

    this.setState({
      [e.target.name]: value,
    }, () => {
      target.selectionEnd = position;

      const {
        state: {
          unit_measure_price, unit_measure_quantity, price, quantity,
        }, props: { handleEdit, arrayIndex },
      } = this;

      const calculatedValues = {
        total: this.calculateTotal(price, quantity),
      };

      if ((unit_measure_quantity !== '' && unit_measure_quantity !== undefined && unit_measure_quantity != 0)) {
        calculatedValues.unit_measure_price = parseFloat(BigDecimal.divide(price, unit_measure_quantity));
      } else if ((unit_measure_price !== '' && unit_measure_price !== undefined && unit_measure_price != 0)) {
        calculatedValues.unit_measure_quantity = parseFloat(BigDecimal.divide(price, unit_measure_price));
      }

      this.setState(calculatedValues, () => { handleEdit(this.state, arrayIndex, true); });
    });
  }

  onChangeTotal = (e) => {
    let { value } = e.target;
    const { target } = e;
    const { state: { price, quantity }, props: { handleEdit, arrayIndex } } = this;
    if (value !== '') {
      value = parseFloat(value).toFixed(2);
      value = NumberConverter(value);
    }

    const position = target.selectionStart;

    this.setState({
      total: value,
      quantity: quantity || 1,
      price: price !== '' && quantity != 0 ? parseFloat(BigDecimal.divide(value, quantity)) : value,
    }, () => { handleEdit(this.state, arrayIndex, true); target.selectionEnd = position; });
  }

  onChangeTotalUnit = (e) => {
    const { target } = e;
    let { value } = target;
    const { state: { quantity, price, unit_measure_quantity }, props: { handleEdit, arrayIndex } } = this;
    if (value !== '') {
      value = parseFloat(value).toFixed(2);
      value = NumberConverter(value);
    }

    const position = target.selectionStart;

    this.setState({
      total_unit: value,
      unit_measure_quantity: quantity != 0 ? parseFloat(BigDecimal.divide(value, quantity)) : value,
      unit_measure_price: unit_measure_quantity != 0 ? parseFloat(BigDecimal.divide(price, unit_measure_quantity)) : price,
    }, () => { handleEdit(this.state, arrayIndex, true); target.selectionEnd = position; });
  }

  onRemoveItem = () => {
    const { handleDeleteClick, itemsCount } = this.props;
    const { id, uuid, position } = this.state;
    if (position === 0 && itemsCount == 1) {
      this.setState({
        id,
        uuid,
        position,
        name: '',
        price: '',
        quantity: '',
        category_id: '',
        _destroy: 0,
        mounted: true,
        unit: '',
        sku: '',
        received: '',
        received_quantity: '',
        e_type: 'item',
      }, () => { handleDeleteClick(this.state, uuid); });
    } else {
      this.setState({ _destroy: '1' }, () => { handleDeleteClick(this.state, uuid); });
    }
  }

  calculateTotal = (price, quantity) => {
    let total = '';
    if ((price !== '' && price !== undefined) && (quantity !== '' && quantity !== undefined)) {
      total = parseFloat(BigDecimal.multiply(price, quantity));
    }
    return total;
  }

  setTotalPrice = (calculate) => {
    const {
      state: {
        price, quantity, unit_measure_quantity, unit_measure_price, total, total_unit,
      }, props: { calculationMethod, handleEdit, arrayIndex },
    } = this;
    let newTotal = '';
    if ((price !== '' && price !== undefined) && (quantity !== '' && quantity !== undefined)) {
      newTotal = parseFloat(BigDecimal.multiply(price, quantity));
    }
    const calculatedValues = { total: newTotal };
    let changed = false;

    if (calculationMethod === 'unit_measure') {
      if (unit_measure_quantity) {
        if (unit_measure_price) {
          calculatedValues.price = parseFloat(BigDecimal.multiply(unit_measure_quantity, unit_measure_price));
          changed = calculatedValues.price !== price;
        } else {
          calculatedValues.unit_measure_price = parseFloat(BigDecimal.divide(price, unit_measure_quantity));
          changed = calculatedValues.unit_measure_price !== unit_measure_price;
        }
      }
      calculatedValues.total_unit = parseFloat(BigDecimal.multiply(unit_measure_quantity, quantity));
      changed = changed || calculatedValues.total_unit !== total_unit;
      calculatedValues.total = this.calculateTotal(calculatedValues.price || price, quantity);
      changed = changed || calculatedValues.total !== total;
    } else {
      calculatedValues.total = this.calculateTotal(price, quantity);
      changed = calculatedValues.total !== total;
    }

    this.setState(calculatedValues, () => { handleEdit(this.state, arrayIndex, calculate && changed); });
  }

  getUncategorizedOption = () => {
    const {
      state: { category_id },
      props: { showUncategorized, isDraft },
    } = this;
    const wasUncategorized = !isDraft && !category_id;
    if (wasUncategorized || showUncategorized) {
      return (
        <label
          key="nil_label"
          onClick={() => this.onChange({ target: { name: 'category_id', value: null } })}
          className={category_id === null || category_id === '' ? 'checked' : ''}
        >
          {I18n.t('purchase_orders.form.items.uncategorized')}
        </label>
      );
    }
    if (!category_id) {
      return <div className="placeholder">---please select---</div>;
    }
    return null;
  }

  onInputValue = async ({ target: { value } }) => {
    const { categories } = this.props;
    if (this.timer) clearTimeout(this.timer);

    this.setState({ inputValue: value }, () => {
      this.timer = setTimeout(() => {
        this.setState({
          stateCategories: categories.filter(
            entity => (
              `${entity.number} - ${entity.name}`.toLowerCase().includes(value.toLowerCase())
            ),
          ),
        });
      }, 300);
    });
  };

  updateStateCategories = (categories) => {
    this.setState({ stateCategories: categories });
    this.onChange({ target: { name: 'category_id', value: null } });
  }

  escapeValueEdit = (e) => {
    const { addItem } = this.props;
    if (e.key === 'Enter' && addItem()) {
      e.target.blur();
    }
  }

  // escapeEdit = async () => {
  //   const { saveDraft } = this.props;
  //   await saveDraft();
  // }

  updateReceive = (receive) => {
    const { handleEdit, arrayIndex } = this.props;
    this.setState({
      received: receive.state.received,
      received_quantity: receive.state.received_quantity,
    }, () => { handleEdit(this.state, arrayIndex, true); });
  }

  countTotalCost = () => {
    const { state: { total }, props: { rate, additional } } = this;
    const totalDecimal = new BigDecimal(total);
    const rateDecimal = new BigDecimal(rate);
    const additionalDecimal = new BigDecimal(additional);

    return parseFloat(totalDecimal.multiply(rateDecimal).add(additionalDecimal).round(2).getValue());
  }

  assignDefaultFocus = (input) => {
    const { focus } = this.state;
    if (this.isDisabled()) return;
    const inputToByFocused = input;
    inputToByFocused.closest('.select').firstElementChild.checked = true;
    if (focus) inputToByFocused.focus();
    this.setState({ mounted: false });
  }

  isDisabled = () => {
    const { state: { id }, props: { isDraft } } = this;
    return isDraft && !id;
  }

  render() {
    const {
      state: {
        _destroy, mounted, sku, name, category_id, quantity, unit_measure_quantity, unit_measure_price,
        price, unit, total_unit, total, currency, supplierItems, inputValue, stateCategories,
      },
      props: {
        item: { errors }, item: propsItem,
        editable, companyCurrency, showDelivery,
        receiveEditable, calculationMethod,
      },
    } = this;
    const htmlClasses = this.isDisabled() ? 'disabled' : '';

    return (
      <tr
        className={htmlClasses}
        style={_destroy == 1 ? { display: 'none' } : {}}
      >
        <td
          className={`no ${errors && errors.sku ? 'has-error' : ''}`}
          data-th={I18n.t('purchase_orders.form.items.sku')}
        >
          <span>
            <span className="select input-table auto-position auto-content-width">
              <input type="checkbox" />
              <div className={`control ${supplierItems.length == 0 ? 'empty' : null}`}>
                <input
                  ref={input => input && mounted && this.assignDefaultFocus(input)}
                  type="text"
                  value={sku}
                  name="sku"
                  placeholder="0"
                  onChange={this.onChangeItemNumber}
                  tabIndex="-1"
                  onFocus={(e) => { e.target.select(); }}
                />
                {errors && errors.sku ? <ItemError value={errors.sku} /> : ''}
              </div>
              <div className="select-content">
                {
                  supplierItems.map(item => (
                    <label onMouseDown={() => this.onClick(item)}>{item.number}</label>
                  ))
                }
                {
                  sku === ''
                    ? (
                      <div className="placeholder" data-placeholder="0" />
                    )
                    : (
                      <div className="placeholder value" data-placeholder={sku} />
                    )
                }
              </div>
            </span>
          </span>
        </td>
        <td
          className={(errors && errors.name ? 'has-error desc' : 'desc')}
          data-th={I18n.t('purchase_orders.form.items.description')}
        >
          <span>
            <Textarea
              value={name}
              disabled={!editable}
              name="name"
              placeholder={I18n.t('purchase_orders.form.items.item_name')}
              onChange={this.onChange}
              // onBlur={this.escapeEdit}
              className="input-table"
              extraHeight={1}
            />
            {errors && errors.name ? <ItemError value={errors.name} /> : ''}
          </span>
        </td>
        <td
          className={
            (errors && (errors.category_id || errors.category_id)
              ? 'has-error category'
              : 'category')
          }
          data-th={I18n.t('purchase_orders.form.items.budget_category')}
        >
          <span>
            <div className="select auto-position auto-content-width input-table input-r">
              <input type="checkbox" />
              <div className="control">
                <input
                  placeholder={I18n.t('commons.actions.enter')}
                  tabIndex="-1"
                  type="text"
                  onChange={this.onInputValue}
                  value={inputValue}
                />
              </div>
              <div className="select-content">
                {this.getUncategorizedOption()}
                {
                  stateCategories.map(category => (
                    <label
                      key={`${category.id}_lable`}
                      onClick={async () => { await this.onCategoryChange(category.id); }}
                      className={category.id === category_id ? 'checked' : ''}
                    >
                      {category.number ? `${category.number} - ${category.name}` : category.name}
                    </label>
                  ))
                }
              </div>
              <span />
            </div>
            {
              errors && errors.category_id
                ? <ItemError value={errors.category_id} />
                : ''
            }
          </span>
        </td>
        <td
          className={`number ${errors && errors.quantity ? 'has-error' : ''}`}
          data-th={I18n.t('purchase_orders.form.items.quantity')}
        >
          <span name="quantity">
            <Textarea
              value={quantity}
              displayValue={I18n.toNumber(quantity, { delimiter: ' ', precision: 2 })}
              name="quantity"
              isNumber
              placeholder="0.00"
              disabled={!editable}
              onChange={this.onNumberChange}
              // onBlur={this.escapeEdit}
              className="input-table"
            />
            {
              errors && errors.quantity
                ? <ItemError value={errors.quantity} />
                : ''
            }
          </span>
        </td>
        <td
          className={`number price ${errors && errors.unit ? 'has-error' : ''}`}
          data-th={I18n.t('purchase_orders.form.items.unit')}
        >
          <span>
            <Textarea
              value={unit}
              name="unit"
              isNumber
              placeholder={I18n.t('purchase_orders.form.items.unit')}
              onChange={this.onChange}
              disabled={!editable}
              // onBlur={this.escapeEdit}
              className="input-table"
            />
            {errors && errors.unit ? <ItemError value={errors.unit} /> : ''}
          </span>
        </td>
        {calculationMethod === 'unit_measure' ? (
          <>
            <td
              className={`number ${errors && errors.unit_measure_quantity ? 'has-error' : ''}`}
              data-th={I18n.t('purchase_orders.form.items.unit_measure_quantity')}
            >
              <span name="unit_measure_quantity">
                <Textarea
                  value={unit_measure_quantity}
                  displayValue={I18n.toNumber(unit_measure_quantity, { delimiter: ' ', precision: 2 })}
                  name="unit_measure_quantity"
                  placeholder="0.00"
                  isNumber
                  disabled={!editable}
                  onChange={this.onNumberChange}
                  // onBlur={this.escapeEdit}
                  className="input-table"
                />
                {
                  errors && errors.unit_measure_quantity
                    ? <ItemError value={errors.unit_measure_quantity} />
                    : ''
                }
              </span>
            </td>
            <td
              className={`number ${errors && errors.unit_measure_price ? 'has-error' : ''}`}
              data-th={I18n.t('purchase_orders.form.items.unit_measure_price')}
            >
              <span name="unit_measure_price">
                <Textarea
                  value={unit_measure_price}
                  displayValue={I18n.toNumber(unit_measure_price, { delimiter: ' ', precision: 2 })}
                  name="unit_measure_price"
                  placeholder="0.00"
                  isNumber
                  disabled={!editable}
                  onChange={this.onNumberChange}
                  // onBlur={this.escapeEdit}
                  className="input-table"
                />
                {
                  errors && errors.unit_measure_price
                    ? <ItemError value={errors.unit_measure_price} />
                    : ''
                }
              </span>
            </td>
          </>
        ) : null}
        <td
          className={`number price ${errors && errors.price_cents
            ? 'has-error'
            : ''}`}
          data-th={I18n.t('purchase_orders.form.items.unit_price')}
        >
          <span name="unit_price">
            <Textarea
              value={price}
              displayValue={I18n.toNumber(price, { delimiter: ' ', precision: 2 })}
              name="price"
              placeholder="0.00"
              isNumber
              onChange={calculationMethod === 'unit_measure' ? this.onUnitMeasurePriceChange : this.onNumberChange}
              disabled={!editable}
              // onBlur={this.escapeEdit}
              className="input-table"
            />
            {
              errors && errors.price_cents
                ? <ItemError value={errors.price_cents} />
                : ''
            }
          </span>
        </td>
        {calculationMethod === 'unit_measure' ? (
          <td
            className="number price"
            data-th={I18n.t('purchase_orders.form.items.total_unit')}
          >
            <span>
              <Textarea
                value={total_unit}
                displayValue={I18n.toNumber(total_unit, { delimiter: ' ', precision: 2 })}
                name="total_unit"
                placeholder="0.00"
                isNumber
                onChange={this.onChangeTotalUnit}
                disabled={!editable}
                // onBlur={this.escapeEdit}
                onKeyPress={this.escapeValueEdit}
                className="input-table"
              />
            </span>
          </td>
        ) : null}
        <td
          className="number price"
          data-th={I18n.t('purchase_orders.form.items.value')}
        >
          <span>
            <Textarea
              value={total}
              displayValue={I18n.toNumber(total, { delimiter: ' ', precision: 2 })}
              name="total"
              placeholder="0.00"
              isNumber
              onChange={this.onChangeTotal}
              disabled={!editable}
              // onBlur={this.escapeEdit}
              onKeyPress={this.escapeValueEdit}
              className="input-table"
            />
          </span>
        </td>
        <td
          className="number price"
          data-th={I18n.t('purchase_orders.form.items.value')}
        >
          <span>
            {
              I18n.toNumber(companyCurrency != currency
                ? this.countTotalCost()
                : total, { delimiter: ' ', precision: 2 })
            }
          </span>
        </td>

        {showDelivery
          ? (
            <Receive
              updateReceive={this.updateReceive}
              item={this.state}
              propsItem={propsItem}
              receiveEditable={receiveEditable}
              editable={editable}
            >
              <td className="colored actions set">
                <span>
                  {editable ? (
                    <a className="on-hover remove" onClick={this.onRemoveItem}>
                      <i className="icon-delete_forever" />
                      <span className="mobile-inline">Remove item</span>
                    </a>
                  ) : ''}
                </span>
                <DragItemHandle />
              </td>
            </Receive>
          ) : (
            <td className="actions set">
              <span>
                {editable ? (
                  <a className="on-hover remove" onClick={this.onRemoveItem}>
                    <i className="icon-delete_forever" />
                    <span className="mobile-inline">Remove item</span>
                  </a>
                ) : ''}
              </span>
              <DragItemHandle />
            </td>
          )}
      </tr>
    );
  }
}

export default OrderItem;
