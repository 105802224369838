import React from 'react';
import PropTypes from 'prop-types';
import { withUUID } from '@/components/shared/utils';
import * as api from '@/components/shared/api';
import * as toast from '@/components/shared/toast';
import FileFormatValidator from '@/components/shared/file_format_validator';
import NotificationBar from '@/components/shared/notificationBar';

class AttachmentModal extends React.Component {
  static propTypes = {
    attachment: PropTypes.object,
    isOpen: PropTypes.bool,
    type: PropTypes.string,
    closeAttachmentModal: PropTypes.func,
  }

  state = {
    files: [],
    processing: false,
    errors: [],
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.resetFormState = this.resetFormState.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onFileChange(e) {
    const { type, externalFilesLimitSize, externalFilesLimitRemaining } = this.props;
    const files = Array.from(e.target.files);
    const error = files.some((file) => {
      if (type === 'internal' && file.size > 20000000) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
        return true;
      }
      if (type === 'external' && file.size > externalFilesLimitRemaining * 1048576) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.total_files_size_to_big', { size: externalFilesLimitSize, remaining: externalFilesLimitRemaining }));
        return true;
      }
      if (file.name.split('.').length > 2) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
        return true;
      }
      if (FileFormatValidator.valid(file, /(\.jpg|\.jpeg|\.gif|\.png|\.pdf|\.xlsx|\.xls|\.doc|\.docx|\.txt|\.rar|\.zip|\.eml|\.msg|\.csv)$/i)) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
        return true;
      }
    });
    if (!error) {
      this.setState({
        files: withUUID(files),
      });
    }

    e.target.value = null;
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.clearFormState();
    }
  }

  submit(e) {
    const { props: { type, addAttachments }, state: { files } } = this;
    e.preventDefault();
    this.setState({ processing: true }, () => {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('purchase_order_files[][attachment]', file);
        formData.append('purchase_order_files[][category]', type);
      });

      api.makePostFileRequest('files/create_all', formData)
        .then((response) => {
          this.setState({ files: [], processing: false });
          const { data } = response;
          addAttachments(data);
          toast.successToast(I18n.t('attachments.messages.added'));
        }).catch((error) => {
          this.setState({ errors: error.response.data.errors });
          this.setState({ files: [], processing: false });
          const responseError = error.response.data.errors[0];
          if (responseError) {
            toast.errorToast(Object.values(responseError).flatten()[0]);
          }
        });
    });
  }

  clearFormState() {
    const { closeAttachmentModal } = this.props;
    this.setState({ files: [], processing: false });
    closeAttachmentModal();
  }

  resetFormState(file) {
    const { state: { files } } = this;
    this.setState(
      {
        files: files.filter(stateFile => stateFile.uuid != file.uuid),
        processing: false,
      },
    );
  }

  render() {
    const {
      props: {
        type, isOpen, externalFilesLimitReached, externalFilesLimitSize, externalFilesLimitRemaining,
      },
      state: {
        processing, files,
      },
    } = this;

    const fileLabel = type == 'external' ? 'attachment' : 'internal file';

    return (
      [
        <input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.submit}>
            <div className="modal-window window-inside window">
              <div className="window-header padding-bottom-2 modal-header window-header-auto">
                <h2>
                  {I18n.t('purchase_orders.form.upload_new')}
                  {' '}
                  {fileLabel}
                  {' '}
                  {I18n.t('purchase_orders.form.to_po')}
                </h2>
              </div>
              {type === 'external' && externalFilesLimitReached && <NotificationBar mainClass="" type="error" iconName="icon-email" content={I18n.t('purchase_orders.external_file_upload_error', { size: externalFilesLimitSize })} />}
              <div className="window-content">
                <div className="grid vertical-start">
                  <div className="row">
                    <div className="cell">
                      <p className="mute">
                        {type === 'external' ? I18n.t('purchase_orders.form.external_file_format_info', { size: externalFilesLimitSize, remaining: externalFilesLimitRemaining })
                          : I18n.t('purchase_orders.form.file_format_info')}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="cell">
                      <div className="as-input required file-button has-progress">
                        <label className="drop-zone">
                          <i className="icon-upload_file" />
                          <span className="mute">
                            {I18n.translate('vouchers.edit.modal.drag_and_drop')}
                            <br />
                            {I18n.translate('vouchers.edit.modal.or')}
                          </span>
                          <input disabled={type === 'external' && externalFilesLimitReached} type="file" onChange={this.onFileChange} name="file" multiple="multiple" />
                          <div className={`button button-s block button-primary file-name no-shadow${processing ? ' hide' : ''}`}>{I18n.t('purchase_orders.form.select_file')}</div>
                        </label>
                        <div className={files.length !== 0 ? 'progress animate' : 'progress hide'}>
                          <div style={{ width: `${processing ? '100%' : '0'}` }} />
                        </div>
                        {
                          files.map(file => (
                            <div key={file.uuid} className={file ? 'row' : 'row hide'}>
                              <span className="file-name state-info">{file.name || ''}</span>
                              {
                                !processing
                                && <button type="reset" onClick={() => this.resetFormState(file)} className="icon-close remove" />
                              }
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={this.clearFormState}>{I18n.t('commons.actions.close')}</label>
                  {
                    (!processing && files.length !== 0)
                    && <input type="submit" value={I18n.t('purchase_orders.form.upload')} className="button button-primary" />
                  }
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={this.clearFormState} />
        </div>,
      ]
    );
  }
}

export default AttachmentModal;
