import React from 'react';
import { errorToast, successToast } from './shared/toast';
import VoucherService from '../services/vouchers_service';
import AccountingsService from '../services/accountings_service';
import VoucherModel from '../models/voucher_model';
import VoucherTransactionModel from '../models/voucher_transaction_model';
import Header from './voucher/header';
import Content from './voucher/content';
import Loader from './loader';
import Errors from '../models/errors';

class Voucher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voucher: new VoucherModel({
        id: props.voucherId,
        transactions: [],
        comments: [],
        supplier: { id: '' },
      }),
      isLoading: true,
      isSubmitting: false,
      voucherErrors: new Errors({}),
      transactionsErrors: {},
    };
  }

  componentDidMount = () => {
    this.fetchVoucher();
  };

  fetchVoucher = () => {
    const { voucherId } = this.props;

    VoucherService.edit(voucherId).then(({ errors, ...params }) => {
      const voucher = this.initVoucher(params);
      if (voucher.processing) this.subscribePredictions();

      this.setState({
        voucher, isLoading: false, voucherErrors: new Errors(errors.voucher_errors), transactionsErrors: errors.transaction_errors,
      });
    });
  };

  forceOCRProcessing = () => {
    const { voucherId } = this.props;

    VoucherService.terminateOCRProcess(voucherId)
      .then(() => {
        successToast(I18n.t('commons.messages.terminated'));
        this.fetchVoucher();
      })
      .catch(() => {
        this.setState({ isLoading: false, isSubmitting: false });
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  initVoucher = (voucher) => {
    if (voucher.transactions.length === 0) {
      voucher.transactions.push(new VoucherTransactionModel({}));
    }

    return new VoucherModel(voucher);
  };

  updateVoucher = (newVoucher, callback = () => {}) => {
    this.setState({ voucher: newVoucher }, callback);
  };

  saveVoucher = (
    event = false,
    approverIds = false,
    displayToast = true,
    successCallback = () => {},
  ) => {
    const {
      state: { voucher, isSubmitting },
    } = this;
    voucher.event = event;
    voucher.approverIds = approverIds;
    const voucherData = voucher.toFormData(voucher);

    if (isSubmitting) return;

    this.clearErrors();
    this.setState({ isSubmitting: true }, () => {
      VoucherService.update(voucher.id, voucherData)
        .then(({ errors, ...params }) => {
          this.setState({ voucher: new VoucherModel(params), isSubmitting: false });
          this.setState({ voucherErrors: new Errors(errors.voucher_errors), transactionsErrors: errors.transaction_errors });
          successCallback();
          if (displayToast) {
            successToast(I18n.t('commons.messages.changes_saved'));
          }
        })
        .catch(({ response: { data } }) => {
          this.setState({ isLoading: false, isSubmitting: false });
          this.assignErrors(data);
          errorToast(I18n.t('commons.messages.error'));
        });
    });
  };

  restoreVoucher = () => {
    const {
      state: {
        voucher: { id },
      },
    } = this;

    AccountingsService.vouchersBulkRestore([id])
      .then(() => {
        window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
      })
      .catch(() => {
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  updateOcrFields = (voucher, data) => {
    this.setState({ isLoading: true });

    const updateOcrFieldsAction = () => VoucherService.updateOcrFields(voucher, data).then((params) => {
      this.setState({ voucher: new VoucherModel(params), isLoading: false });
    });

    this.saveVoucher(false, false, true, updateOcrFieldsAction);
  };

  saveVoucherComments = (comment) => {
    const {
      state: { voucher },
    } = this;
    VoucherService.createComment(voucher, comment)
      .then(() => {
        successToast(I18n.t('commons.messages.changes_saved'));
      })
      .catch(() => {
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  saveWithEvent = (newParams) => {
    const {
      state: { voucher },
    } = this;
    VoucherService.updateWithEvent(voucher, { voucher: newParams })
      .then((params) => {
        this.setState({ voucher: new VoucherModel(params) });
        this.clearErrors();
        successToast(I18n.t('commons.messages.changes_saved'));
      })
      .catch(({ response: { data } }) => {
        this.assignErrors(data);
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  transferVoucher = async () => {
    const {
      props: { integrationType },
      state: { voucher, isSubmitting },
    } = this;

    if (isSubmitting) return;

    const transferAction = () => VoucherService.postInvoice(voucher)
      .then((params) => {
        this.setState({
          voucher: new VoucherModel(params),
          isLoading: false,
        });
        this.clearErrors();
        successToast(I18n.t('commons.messages.transfer_succeed'));
        if (integrationType === 'manual') this.exportVoucher();
      })
      .catch(({ response: { data } }) => {
        this.assignErrors(data);
        errorToast(I18n.t('commons.messages.error'));
        this.setState({ isLoading: false });
      });
    this.saveVoucher(false, false, true, transferAction);
  };

  exportVoucher = () => {
    const { voucher } = this.state;
    const query = `invoice_ids[]=${voucher.id}`;
    window.location.href = `/api/accountings/vouchers/transfer_export.xlsx?${query}`;
  };

  saveAndCloseVoucher = () => {
    const {
      state: { voucher },
    } = this;
    const voucherData = voucher.toFormData(voucher);
    VoucherService.update(voucher.id, voucherData)
      .then((params) => {
        this.setState({ voucher: new VoucherModel(params) });
        window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
      })
      .catch(({ response: { data } }) => {
        this.assignErrors(data);
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  deleteVoucher = () => {
    const { voucher } = this.state;
    VoucherService.destroy(voucher).then(() => {
      window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
    });
  };

  clearErrors = () => {
    this.assignErrors({ voucher: { errors: {} }, transactions: {} });
  };

  assignErrors = (data) => {
    const { voucher, transactions } = data;

    if (voucher !== undefined) {
      this.setState({
        voucherErrors: new Errors(voucher.errors),
        transactionsErrors: transactions,
      });
    }
  };

  subscribePredictions = () => {
    const {
      state: { voucher },
    } = this;
    this.subscription = App.cable.subscriptions.create(
      { channel: 'PredictionsChannel', voucher: voucher.id },
      {
        connected: () => {
          VoucherService.active(voucher.id).then(({ jobProcessing }) => {
            voucher.processing = jobProcessing;
            if (!voucher.processing) {
              this.fetchVoucher();
            } else {
              this.setState({ voucher });
            }
          });
        },
        received: (success) => {
          if (success) {
            this.fetchVoucher();
          }
          this.subscription.unsubscribe();
        },
        disconnected: () => this.subscription.unsubscribe(),
      },
    );
  };

  render = () => {
    const {
      voucher, isLoading, voucherErrors, transactionsErrors,
    } = this.state;
    const {
      currentUser,
      companyCurrency,
      serverTime,
      integrationType,
      enableClass,
      enableCustomer,
      enableLocation,
      standAlone,
    } = this.props;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div
        className={`modal centered modal-fullscreen with-frame active ${htmlClasses}`}
      >
        <div className="modal-wrapper">
          <Loader />
          <div className="modal-window window window-form">
            <Header
              voucher={voucher}
              serverTime={serverTime}
              forceOCRProcessing={this.forceOCRProcessing}
            />
            <Content
              voucher={voucher}
              voucherErrors={voucherErrors}
              transactionsErrors={transactionsErrors}
              updateVoucher={this.updateVoucher}
              updateOcrFields={this.updateOcrFields}
              saveVoucher={this.saveVoucher}
              saveAndCloseVoucher={this.saveAndCloseVoucher}
              restoreVoucher={this.restoreVoucher}
              deleteVoucher={this.deleteVoucher}
              currentUser={currentUser}
              companyCurrency={companyCurrency}
              saveWithEvent={this.saveWithEvent}
              transferVoucher={this.transferVoucher}
              possibleApprovers={voucher.possibleApprovers}
              saveVoucherComments={this.saveVoucherComments}
              integrationType={integrationType}
              enableClass={enableClass}
              enableCustomer={enableCustomer}
              enableLocation={enableLocation}
              standAlone={standAlone}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default Voucher;
