import React from 'react';
import LastSavedAtTimer from '../purchase_order/last_saved_at_timer';

function Header({ voucher, serverTime, forceOCRProcessing }) {
  const closeModal = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get('from');
    if (from === 'approval') {
      window.location.href = '/purchase_orders/approval?tab=invoices';
    } else {
      window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
    }
  };

  return (
    <React.Fragment>
      <div className="window-header modal-header">
        <span>
          {I18n.t('vouchers.edit.invoice_header')}
          <span className="mute">{` ${voucher.number}`}</span>
        </span>
        <span>
          <LastSavedAtTimer serverTime={serverTime} updatedAt={voucher.updatedAt} />
          <label className="mobile-inline-block" htmlFor="internal-comment-modal">
            <i className="icon-question_answer" />
          </label>
          <label className="modal-close" htmlFor="modal-close" onClick={closeModal}>
            <i className="icon-close" />
          </label>
        </span>
      </div>
      <div className="window-toasts">
        {voucher.outdated
          && (
            <div className="toast show error">
              <i className="icon-info" />
              {' '}
              {I18n.t('errors.voucher.outdated_title')}
            </div>
          )
        }
        {voucher.processing
          && (
            <div className="toast show info">
              <i className="icon-hourglass_empty" />
              {' '}
              {I18n.t('vouchers.edit.currently_processed')}
              <div className="button" onClick={forceOCRProcessing}>Cancel</div>
            </div>
          )
        }
      </div>
    </React.Fragment>
  );
}

export default Header;
