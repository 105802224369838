import React from 'react';
import PropTypes from 'prop-types';

class RejectModal extends React.Component {
  state = {
    body: '',
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    submit: PropTypes.func,
    handleClose: PropTypes.func,
    errors: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.reject = this.reject.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  escFunction(event) {
    const { handleClose } = this.props;
    if (event.keyCode === 27) {
      handleClose();
    }
  }

  reject(e) {
    const { submit } = this.props;
    e.preventDefault();
    submit();
  }

  render() {
    const {
      props: {
        isOpen, errors, handleClose,
      },
      state: {
        body,
      },
    } = this;
    return (
      [
        <input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} readOnly />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.reject}>
            <div className="modal-window window-inside window">
              <div className="window-content">
                <div className="grid space-1 gutter-1">
                  <div className="row">
                    <div className={`cell-16 ${errors.action_body ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <h2>{I18n.t('purchase_orders.form.add_comment')}</h2>
                        <p>
                          {I18n.t('purchase_orders.form.reject_comment_message')}
                        </p>
                        <div className="as-input required">
                          <textarea id="reject_body" name="body" onChange={this.onChange} value={body} placeholder={I18n.t('purchase_orders.form.enter_comment')} />
                          <span className="hint error">{errors.action_body}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={handleClose}>{I18n.t('purchase_orders.form.cancel')}</label>
                  <button className="button button-danger">{I18n.t('purchase_orders.form.reject_po')}</button>
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={handleClose} />
        </div>,
      ]
    );
  }
}
export default RejectModal;
