import uuid from 'uuid/v4';

class VoucherAttachmentModel {
  constructor({ url }) {
    this.url = url || '';
    this.uuid = uuid();
    this.transform = 'rotate(0deg)';
    this.transformValue = 0;
  }

  toFile = () => new File([`${this.url}`], 'attachment', { type: 'image/png' });
}

export default VoucherAttachmentModel;
