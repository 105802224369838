import React, { useState, useEffect } from 'react';
import UserRow from './send_to_approval_modal/user_row';
import Button from '../../common/button';

function SendToApprovalModal({
  possibleApprovers, sendToApprove, activeModel, setActiveModal,
}) {
  const [approvers, setApprovers] = useState(possibleApprovers);

  useEffect(() => {
    setApprovers(possibleApprovers);
  }, [possibleApprovers]);

  const selectUser = (newUser) => {
    const newApprovers = approvers.map((approver) => {
      if (approver.uuid === newUser.uuid) {
        return newUser;
      }
      return approver;
    });

    setApprovers(newApprovers);
  };

  const onSend = () => {
    const approverIds = approvers.filter(user => user.selected).map(user => user.id);
    sendToApprove(approverIds);
  };

  const closeModal = () => {
    setActiveModal(false);
  };

  const clearAll = () => {
    const newApprovers = approvers.map((user) => {
      const newUser = user;
      newUser.selected = false;
      return newUser;
    });

    setApprovers(newApprovers);
  };

  const active = activeModel ? 'active' : '';

  return (
    <div className={`modal sub-modal modal-table centered modal-table-search ${active}`}>
      <div className="modal-wrapper">
        <div className="modal-window window window-form">
          <div className="window-header modal-header grid space-1">
            <div className="row">
              <div className="cell">
                <h3 className="margin-top-0">
                  { I18n.t('vouchers.modals.send_to_approval.title') }
                </h3>
                <p className="mute">
                  { I18n.t('vouchers.modals.send_to_approval.description') }
                </p>
              </div>
            </div>
          </div>
          <div className="window-content">
            <table className="grid">
              <tbody>
                {
                  approvers.map(approver => (
                    <UserRow selectUser={selectUser} user={approver} key={approver.uuid} />
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="window-footer modal-footer">
            <div className="items items-end center">
              <Button className="inverse button-mute" onClick={closeModal}>{ I18n.t('commons.actions.close') }</Button>
              <Button className="inverse button-mute" onClick={clearAll}>{ I18n.t('commons.actions.clear_all') }</Button>
              <Button primary onClick={onSend}>{ I18n.t('commons.actions.send') }</Button>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={closeModal} htmlFor="send_to_approval-modal-close" />
    </div>
  );
}

export default SendToApprovalModal;
