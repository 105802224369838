import React from 'react';
import * as api from '../../shared/api';
import * as toast from '../../shared/toast';

class AddressContactsModal extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    errors: {},
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.clear = this.clear.bind(this);
    this.prepareBody = this.prepareBody.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  escFunction(event) {
    const { closeModal } = this.props;
    if (event.keyCode === 27) {
      closeModal();
    }
  }

  prepareBody() {
    const {
      name, email, phone,
    } = this.state;
    return {
      address_contact: {
        name,
        email,
        phone,
      },
    };
  }

  submit(e) {
    const { handleAddAddressContact, addressId } = this.props;
    e.preventDefault();
    api.makePostRequest(`/settings/addresses/${addressId}/contacts`, this.prepareBody())
      .then((response) => {
        this.clear();
        this.close();
        handleAddAddressContact(response.data, addressId);
        toast.successToast(I18n.t('settings.addresses.messages.created'));
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  }

  clear() {
    this.setState({
      name: '',
      email: '',
      phone: '',
    });
  }

  close() {
    const { closeModal } = this.props;
    closeModal();
  }

  render() {
    const {
      props: {
        isOpen, closeModal, type,
      },
      state: {
        errors, email, phone, name,
      },
    } = this;

    return (
      [<input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.submit}>
            <div className="modal-window window-inside window">
              <div className="window-header modal-header">
                {I18n.t(`purchase_orders.form.add_${type}_contact_person`)}
              </div>
              <div className="window-content">
                <div className="grid space-1 gutter-1">
                  <div className="row">
                    <div className={`cell-16 ${errors.name ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.name')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_name')}
                          id="our_contact_name"
                          name="name"
                          type="text"
                          value={name}
                          onChange={this.onChange}
                        />
                        { errors.name
                          ? <div className="hint">{errors.name.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.email ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.email')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_email')}
                          id="our_contact_email"
                          name="email"
                          type="text"
                          value={email}
                          onChange={this.onChange}
                        />
                        { errors.email
                          ? <div className="hint">{errors.email.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.phone ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">
                          {I18n.t('purchase_orders.form.phone_number')}
                        </span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_phone_number')}
                          id="our_contact_phone"
                          name="phone"
                          type="text"
                          value={phone}
                          onChange={this.onChange}
                        />
                        { errors.phone
                          ? <div className="hint">{errors.phone.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label
                    className="button button-transparent button-mute inverse link"
                    onClick={closeModal}
                  >
                    {I18n.t('purchase_orders.form.cancel')}
                  </label>
                  <button className="button button-primary">{I18n.t('purchase_orders.form.save')}</button>
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={closeModal} />
        </div>]
    );
  }
}

export default AddressContactsModal;
