import React, { useState } from 'react';
import VoucherContext from '@/context/voucher_context';
import Buttons from './buttons';
import SideBar from './side_bar';
import TransactionsSection from './transactions_section';
import ImageViewer from './image_viewer';
import SideImageSection from './side_image_section';
import BudgetCategoryAttach from '../accountings/invoices/budget_category_attach';
import PurchaseOrderAttach from '../accountings/invoices/purchase_order_attach';
import WindowToast from '../shared/window_toast';
import PurchaseOrderPreview from '../purchase_order_preview';
import AttachInfoModal from './modals/attach_info_modal';

function Content({
  voucher, updateVoucher, saveVoucher, restoreVoucher, saveAndCloseVoucher,
  deleteVoucher, currentUser, saveWithEvent, possibleApprovers,
  transferVoucher, saveVoucherComments, voucherErrors,
  transactionsErrors, companyCurrency, updateOcrFields,
  integrationType, enableClass, enableCustomer, enableLocation, standAlone,
}) {
  const [attachInvoices, setAttachInvoices] = useState([]);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [openAttachToBudgetCategory, setOpenAttachToBudgetCategory] = useState(false);
  const [openAttachToPurchaseOrder, setOpenAttachToPurchaseOrder] = useState(false);
  const [poPreviewOpen, setPoPreviewOpen] = useState(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState(null);
  const [attachInfoModalOpen, setAttachInfoModalOpen] = useState(false);

  const onAttachToPurchaseOrder = (invoices) => {
    setOpenAttachToPurchaseOrder(!openAttachToPurchaseOrder);
    setAttachInvoices(invoices);
  };

  const onAttachToBudget = (invoices) => {
    setOpenAttachToBudgetCategory(!openAttachToBudgetCategory);
    setAttachInvoices(invoices);
  };

  const toggleSideBarOpen = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const toggleOpenAttachToBudgetCategory = () => {
    setOpenAttachToBudgetCategory(!openAttachToBudgetCategory);
  };

  const toggleOpenAttachToPurchaseOrder = () => {
    setOpenAttachToPurchaseOrder(!openAttachToPurchaseOrder);
  };

  const updateVoucherTransactions = () => {
    saveVoucher(false, false, false);
  };

  const togglePoPreviewOpen = (id) => {
    if (id && purchaseOrderId !== id) {
      setPurchaseOrderId(id);

      if (poPreviewOpen) return;
    }
    setPoPreviewOpen(!poPreviewOpen);
  };

  const htmlClass = sideBarOpen ? '' : 'collapse';

  return (
    <React.Fragment>
      {openAttachToBudgetCategory
        ? (
          <BudgetCategoryAttach
            onToggleAttachBudgetCategorySettings={toggleOpenAttachToBudgetCategory}
            filter={updateVoucherTransactions}
            invoices={attachInvoices}
          />
        ) : null}
      {openAttachToPurchaseOrder
        ? (
          <VoucherContext.Provider value={voucher}>
            <PurchaseOrderAttach
              onToggleAttachPoSettings={toggleOpenAttachToPurchaseOrder}
              invoices={attachInvoices}
              filter={updateVoucherTransactions}
              fromInvoice
              fromVoucher
              supplierId={voucher.supplier.id}
            />
          </VoucherContext.Provider>
        ) : null}
      <div className="window-content with-frame">
        <div className={`window-wrapper grid with-frame ${htmlClass}`}>
          <AttachInfoModal
            activeModal={attachInfoModalOpen}
            setActiveModal={setAttachInfoModalOpen}
          />
          <div className="window-toasts">
            <WindowToast opened={voucherErrors.api} messages={voucherErrors.api} />
            <WindowToast opened={voucher.duplicated} messages={[I18n.t('vouchers.edit.side_image_section.invoice_number_warning')]} />
          </div>
          <div className="window-sidebar-toggle">
            <i className="icon-attach_file" onClick={toggleSideBarOpen} />
            <i className="icon-question_answer" onClick={toggleSideBarOpen} />
          </div>
          <div className={`form-section cell-12 with-frame ${poPreviewOpen && 'with-popover'}`}>
            <div className="window-frame">
              <ImageViewer voucher={voucher} />
              <SideImageSection
                errors={voucherErrors}
                voucher={voucher}
                updateVoucher={updateVoucher}
                updateOcrFields={updateOcrFields}
                companyCurrency={companyCurrency}
                integrationType={integrationType}
                enableLocation={enableLocation}
                standAlone={standAlone}
              />
            </div>
            {poPreviewOpen
              && (
                <PurchaseOrderPreview
                  companyCurrency={companyCurrency}
                  togglePoPreviewOpen={togglePoPreviewOpen}
                  purchaseOrderId={purchaseOrderId}
                  voucherReferenceNumber={voucher.number}
                />
              )
            }
          </div>
          <TransactionsSection
            errors={transactionsErrors}
            voucherErrors={voucherErrors}
            voucher={voucher}
            updateVoucher={updateVoucher}
            onAttachToPurchaseOrder={onAttachToPurchaseOrder}
            onAttachToBudget={onAttachToBudget}
            saveVoucher={saveVoucher}
            currentUser={currentUser}
            integrationType={integrationType}
            togglePoPreviewOpen={togglePoPreviewOpen}
            setAttachInfoModalOpen={setAttachInfoModalOpen}
            enableClass={enableClass}
            enableCustomer={enableCustomer}
          />
          <Buttons
            saveVoucher={saveVoucher}
            restoreVoucher={restoreVoucher}
            saveAndCloseVoucher={saveAndCloseVoucher}
            deleteVoucher={deleteVoucher}
            permittedEvents={voucher.permittedEvents}
            saveWithEvent={saveWithEvent}
            transferVoucher={transferVoucher}
            voucher={voucher}
            possibleApprovers={possibleApprovers}
            currentUser={currentUser}
            integrationType={integrationType}
          />
          <SideBar
            voucher={voucher}
            updateVoucher={updateVoucher}
            currentUser={currentUser}
            toggleSideBarOpen={toggleSideBarOpen}
            saveVoucherComments={saveVoucherComments}
            integrationType={integrationType}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Content;
