import React from 'react';
import Table from '../shared/table';
import * as toast from '../shared/toast';
import TableFluid from './accouting_invoices/table_fluid';
import Footer from './accouting_invoices/footer';
import FiltersAndSearch from './accouting_invoices/filters_and_search';
import BulkActions from './accouting_invoices/bulk_actions';
import AccountingsService from '../../services/accountings_service';
import VoucherModel from '../../models/voucher_model';
import BudgetCategoryAttach from './invoices/budget_category_attach';
import PurchaseOrderAttach from './invoices/purchase_order_attach';
import BulkErrorModal from './accouting_invoices/bulk_error_modal';
import AttachInfoModal from '../voucher/modals/attach_info_modal';
import * as swipe from '../shared/swipe';

class AccountingInvoices extends Table {
  constructor(props) {
    super(props);

    const urlFilters = this.urlParamsSetup();
    const page = urlFilters.page ? parseInt(urlFilters.page) : 1;
    const per = urlFilters.per ? parseInt(urlFilters.per) : 20;
    const completedStates = ['completed'];

    this.filters = urlFilters.filters;
    this.order = urlFilters.order;

    this.state = {
      vouchers: [],
      pages: 1,
      per,
      currentPage: page,
      filters: [],
      filterActions: [],
      filtersComponentKey: 1,
      showCompleted: this.filters.state ? this.filters.state.every((_val, i) => completedStates[i]) : false,
      openBulkActions: false,
      openBulkErrorModal: false,
      attachVouchers: [],
      allVouchersAttached: false,
      openDetach: false,
      openAttachToPurchaseOrder: false,
      openAttachToBudgetCategory: false,
      total: 0,
      showTransactions: false,
      attachInvoices: [],
      attachInfoModalOpen: false,
      transferred: this.filters.state ? !this.filters.state.includes('transferred') : true,
    };
  }

  componentDidMount() {
    const { updateTotalCounts } = this.props;
    swipe.triggerSwipeEvent();
    this.onTableLoading();
    AccountingsService.vouchers(window.location.search).then(({
      vouchers, pages, filters, total, transactionsCount, purchaseOrdersCount,
      invoicesCount,
    }) => {
      this.setState({
        vouchers,
        pages,
        total,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
      });
      this.onTableLoad();
      updateTotalCounts(transactionsCount, purchaseOrdersCount, invoicesCount);
    });
  }

  headers = () => {
    const { companyCurrency, standAlone, xero } = this.props;

    const standardHeader = [
      {
        name: 'internal_number',
        translation: 'purchase_orders.partials.invoices_body.doc_id',
        class: 'pointer',
      },
      {
        name: 'supplier',
        translation: 'purchase_orders.supplier',
        class: 'pointer',
      },
      {
        name: 'doc_number',
        translation: 'purchase_orders.partials.invoices_body.invoice_number',
        class: 'pointer',
      },
      {
        name: 'doc_date',
        translation: 'purchase_orders.partials.invoices_body.doc_date',
        class: 'pointer',
      },
      {
        name: 'due_date',
        translation: 'purchase_orders.partials.invoices_body.due_date',
        class: 'pointer',
      },
      {
        name: 'reference',
        translation: 'purchase_orders.partials.invoices_body.reference',
        class: 'pointer',
      },
      {
        name: 'currency_amount',
        translation: 'purchase_orders.partials.invoices_body.amount',
        class: 'number price pointer',
      },
      {
        name: 'currency',
        translation: 'purchase_orders.partials.invoices_body.currency',
        class: 'pointer',
      },
      {
        name: 'amount',
        translation: 'purchase_orders.partials.invoices_body.amount_currency',
        attributes: { currency: companyCurrency },
        class: 'number price pointer',
      },
      {
        name: 'created_at',
        translation: 'purchase_orders.partials.invoices_body.created',
        class: 'pointer',
      },
      {
        name: 'transferred_at',
        translation: 'purchase_orders.partials.invoices_body.transferred_at',
        class: 'pointer',
      },
      {
        name: 'status',
        translation: 'purchase_orders.partials.invoices_body.status',
        class: 'has-status pointer',
      },
    ];

    const paidHeader = {
      name: 'paid',
      translation: 'purchase_orders.partials.invoices_body.paid',
      class: 'pointer',
    };

    (standAlone || xero) && standardHeader.splice(standardHeader.length - 1, 0, paidHeader);

    return standardHeader;
  }

  onFilter = ({ name, value }) => {
    const stringValue = value.toString();
    this.filters[name] = [...this.filters[name] || [], stringValue];

    this.filter();
  }

  onNotDeletedFIlter = ({ value }) => {
    const stringValue = value.toString();

    this.filters.deleted = [stringValue];

    this.filter();
  }

  filterWithCurrentPage = () => this.filter({}, this.state.currentPage);

  filterCompleted = () => {
    const { showCompleted } = this.state;

    if (this.filters.state?.includes('transferred')) {
      this.filters.state = this.filters.state.filter(f => f !== 'transferred');
    } else {
      this.filters.state = [...this.filters.state || [], 'transferred'];
    }

    const uniqFilterKey = Math.floor(Math.random() * 1000);

    this.filter({ showCompleted: !showCompleted, filtersComponentKey: uniqFilterKey });
  }

  filter = (additionalState = {}, page = 1, _, perPage) => {
    delete this.filters.show_completed;
    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page,
      per: perPage || this.state.per,
      order: this.order,
    };

    const { updateTotalInvoicesCounts } = this.props;
    this.onTableLoading();

    return AccountingsService.voucherFilter(attributes).then(({
      vouchers, pages, query, invoicesCount, purchaseOrdersCount, transactionsCount,
    }) => {
      window.history.pushState({}, 'Accounting', query);
      this.setState({
        vouchers: vouchers.map(
          voucher => new VoucherModel(voucher),
        ),
        pages,
        per: attributes.per,
        currentPage: 1,
        filtersComponentKey: Math.floor(Math.random() * 1000),
        transferred: !this.filters.state?.includes('transferred'),
        ...additionalState,
      });
      updateTotalInvoicesCounts(invoicesCount, purchaseOrdersCount, transactionsCount);
      this.onTableLoad();
    });
  }

  onAttachToPurchaseOrder = (invoices) => {
    this.setState({
      openAttachToPurchaseOrder: !this.state.openAttachToPurchaseOrder,
      attachInvoices: invoices,
    });
  }

  onAttachToBudget = (invoices) => {
    this.setState({
      openAttachToBudgetCategory: !this.state.openAttachToBudgetCategory,
      attachInvoices: invoices,
    });
  }

  onToggleAttachPoSettings = () => {
    this.setState({
      openAttachToPurchaseOrder: !this.state.openAttachToPurchaseOrder,
    });
  }

  onToggleAttachInfoModalOpen = () => {
    this.setState({ attachInfoModalOpen: !this.state.attachInfoModalOpen });
  }

  onToggleAttachBudgetCategorySettings = () => {
    this.setState({
      openAttachToBudgetCategory: !this.state.openAttachToBudgetCategory,
    });
  }

  onToggleBulkActions = () => {
    const { openBulkActions, vouchers } = this.state;

    this.setState({
      openBulkActions: !openBulkActions,
      attachVouchers: [],
      allVouchersAttached: false,
      vouchers: vouchers.map((voucher) => {
        const newVoucher = voucher;
        newVoucher.selected = false;
        return newVoucher;
      }),
    });
  }

  onToggleBulkErrorModal = () => {
    const { openBulkErrorModal } = this.state;

    this.setState({ openBulkErrorModal: !openBulkErrorModal });
  }

  onShowTransactions = () => {
    const { showTransactions } = this.state;

    this.setState({ showTransactions: !showTransactions });
  }

  onToggleDetach = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;

    this.setState({
      openDetach: !this.state.openDetach,
      attachVouchers: assignedVouchers,
    });
  }

  onRemove = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;

    AccountingsService.vouchersBulkDestroy((assignedVouchers.map(voucher => voucher.id))).then(() => {
      this.filterWithCurrentPage();
      this.setState({ openBulkActions: false });
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onSendToErp = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;
    const { props: { integrationType } } = this;

    AccountingsService.vouchersBulkSendToErp((assignedVouchers.map(voucher => voucher.id))).then(() => {
      this.filterWithCurrentPage();
      this.setState({ openBulkActions: false });
      if (integrationType === 'manual') this.onTransferExport();
    }).catch(() => {
      this.onToggleBulkErrorModal();
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onSendReminder = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;

    AccountingsService.vouchersBulkSendReminder((assignedVouchers.map(voucher => voucher.id))).then(() => {
      this.filterWithCurrentPage();
      this.setState({ openBulkActions: false });
    }).catch(() => {
      this.onToggleBulkErrorModal();
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onMarkAsPaid = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;

    AccountingsService.vouchersMarkAsPaid((assignedVouchers.map(voucher => voucher.id))).then(() => {
      this.filterWithCurrentPage();
      this.setState({ openBulkActions: false });
    }).catch(() => {
      this.onToggleBulkErrorModal();
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onTransferExport = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;

    this.filterWithCurrentPage().then(() => {
      this.setState({ openBulkActions: false });

      const queries = assignedVouchers.map(voucher => `invoice_ids[]=${voucher.id}`).join('&');
      window.location.href = `/api/accountings/vouchers/transfer_export.xlsx?${queries}`;
    });
  }

  onRestore = (vouchers) => {
    const assignedVouchers = vouchers || this.state.attachVouchers;

    AccountingsService.vouchersBulkRestore((assignedVouchers.map(voucher => voucher.id))).then(() => {
      this.filterWithCurrentPage();
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onBulkSelect = (selectedVoucher) => {
    const attachedVouchers = [];

    const vouchers = this.state.vouchers.map((voucher) => {
      if (voucher.id === selectedVoucher.id) {
        const selected = !voucher.selected;
        const newVoucher = voucher;
        newVoucher.selected = selected;
        if (selected) attachedVouchers.push(newVoucher);
        return newVoucher;
      }
      if (voucher.selected) attachedVouchers.push(voucher);
      return voucher;
    });

    const openBulkActions = attachedVouchers.length > 0;

    this.setState({
      openBulkActions,
      vouchers,
      attachVouchers: attachedVouchers,
      allVouchersAttached: false,
    });
  }

  onBulkSelectAll = () => {
    const { allVouchersAttached, vouchers } = this.state;
    const assignAll = !allVouchersAttached;

    this.setState({
      openBulkActions: assignAll,
      vouchers: vouchers.map((voucher) => {
        const newVoucher = voucher;
        newVoucher.selected = assignAll;
        return newVoucher;
      }),
      attachVouchers: assignAll ? vouchers : [],
      allVouchersAttached: assignAll,
    });
  }

  render() {
    const {
      state: {
        vouchers, filters, filterActions, filtersComponentKey, openBulkActions,
        attachVouchers, pages, currentPage, isLoading, showTransactions,
        openAttachToBudgetCategory, attachInvoices, openAttachToPurchaseOrder,
        openBulkErrorModal, attachInfoModalOpen, transferred,
      },
      props: {
        integrationType, standAlone, xero,
      },
    } = this;

    const { currentUser } = this.props;

    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <div className="tab-pages tile-content">
          <AttachInfoModal
            activeModal={attachInfoModalOpen}
            setActiveModal={this.onToggleAttachInfoModalOpen}
          />
          {openAttachToBudgetCategory
            ? (
              <BudgetCategoryAttach
                onToggleAttachBudgetCategorySettings={this.onToggleAttachBudgetCategorySettings}
                filter={this.filterWithCurrentPage}
                invoices={attachInvoices}
              />
            ) : null}
          {openAttachToPurchaseOrder
            ? (
              <PurchaseOrderAttach
                onToggleAttachPoSettings={this.onToggleAttachPoSettings}
                filter={this.filterWithCurrentPage}
                invoices={attachInvoices}
                currency="NOK"
                fromInvoice
              />
            ) : null}
          <div className="page active">
            <div className="has-bulk">
              {openBulkActions
                ? (
                  <BulkActions
                    vouchers={attachVouchers}
                    filters={this.filters}
                    integrationType={integrationType}
                    onSendToErp={this.onSendToErp}
                    onExport={this.onTransferExport}
                    onToggleBulkActions={this.onToggleBulkActions}
                    onRemove={this.onRemove}
                    onRestore={this.onRestore}
                    onSendReminder={this.onSendReminder}
                    onMarkAsPaid={this.onMarkAsPaid}
                    standAlone={standAlone}
                  />
                ) : (
                  <FiltersAndSearch
                    onFilter={this.onFilter}
                    onRemoveFilter={this.onRemoveFilter}
                    onDateFilter={this.onDateFilter}
                    onQueryFilter={this.onQueryFilter}
                    currentFilters={this.filters}
                    clearDate={this.clearDate}
                    filters={filters}
                    filterActions={filterActions}
                    integrationRefesh={false}
                    key={`availableFiltersPurchaseOrders${filtersComponentKey}`}
                    showTransactions={showTransactions}
                    onShowTransactions={this.onShowTransactions}
                  />
                )}
              <TableFluid
                vouchers={vouchers}
                onBulkSelectAll={this.onBulkSelectAll}
                onBulkSelect={this.onBulkSelect}
                headers={this.headers()}
                order={this.order}
                onOrder={this.onOrder}
                htmlClasses={htmlClasses}
                showTransactions={showTransactions}
                onAttachToPurchaseOrder={this.onAttachToPurchaseOrder}
                onAttachToBudget={this.onAttachToBudget}
                renderDots={this._renderDots}
                renderLabels={this._renderLabels}
                renderInputs={this._renderInputs}
                currentUser={currentUser}
                setAttachInfoModalOpen={this.onToggleAttachInfoModalOpen}
                standAlone={standAlone}
                xero={xero}
              />
            </div>
          </div>
        </div>
        <Footer
          onPageChange={this.onPageChange}
          numberPages={pages}
          currentPage={currentPage}
          per={this.state.per}
          onPerChange={this.onPerChange}
          filterCompleted={this.filterCompleted}
          transferred={transferred}
        />
        {
          openBulkErrorModal && (
            <BulkErrorModal close={this.onToggleBulkErrorModal} />
          )
        }
      </React.Fragment>
    );
  }
}

export default AccountingInvoices;
