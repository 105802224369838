import React from "react";

import IconButton from "./index";

export default {
  title: "IconButton",
  component: IconButton,
  argTypes: {
    text: "Text",
    success: false,
    warning: true,
    onClick: () => console.log('Click')
  },
};

const Template = (args) => <IconButton {...args} />;

export const Default = Template.bind({});

Default.args = {
  text: "Test text",
  success: false,
  warning: true,
  onClick: () => console.log('Click')
};
