import React, { useEffect } from 'react';
import DropdownTableField from './common/dropdown_table_field';
import TableInputField from './common/table_input_field';
import TransactionRowActions from '../accountings/accouting_invoices/transaction_row_actions';
import AccountingsService from '../../services/accountings_service';
import * as toast from '../shared/toast';
import { INTEGRATION_TYPES } from '../../config/constants';

function TransactionRow({
  onUpdateTransaction, transaction, removeTransaction, editable,
  setIsLoadingAccount, setIsLoadingVat, onAttachToPurchaseOrder,
  onAttachToBudget, state, removable, errors, currentUser, voucherType,
  integrationType, trackingCategories, togglePoPreviewOpen, setAttachInfoModalOpen,
  enableClass, enableCustomer, exchangeRate,
}) {
  const attachTransaction = transaction;

  useEffect(() => {
    calculateExVat();
  }, []);

  const onUpdateAmountOrVat = (newTransaction, callback = () => { }) => {
    calculateExVat();
    onUpdateTransaction(newTransaction, callback);
  };

  const onUpdateExVat = (newTransaction, fn) => {
    calculateAmount();
    onUpdateTransaction(newTransaction, fn);
  };

  const onDetach = () => {
    const invoiceIds = [attachTransaction.id];
    const params = {
      budgetId: null, categoryId: null, purchaseOrderId: null, invoiceIds,
    };

    AccountingsService.invoicesBulkUpdate(params).then(() => {
      onDetachAssigned();
      toast.successToast(I18n.t('commons.messages.changes_saved'));
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  };

  const onDetachAssigned = () => {
    const newTransaction = attachTransaction;

    newTransaction.budgetId = null;
    newTransaction.budgetName = null;
    newTransaction.categoryId = null;
    newTransaction.categoryName = null;
    newTransaction.purchaseOrderId = null;
    newTransaction.purchaseOrderNumber = null;

    onUpdateTransaction(newTransaction);
  };

  const calculateExVat = () => {
    const newTransaction = transaction;

    const exVat = transaction.vat && (transaction.vat.rate && !transaction.vat.isReverseCharge) ? (transaction.amount / (1 + transaction.vat.rate / 100)) : transaction.amount;
    const vatAmount = parseFloat(transaction.amount) - parseFloat(exVat);

    newTransaction.vatAmount = vatAmount ? parseFloat(vatAmount).toFixed(2) : '';
    newTransaction.exVat = exVat ? parseFloat(exVat).toFixed(2) : '';
    newTransaction.currencyValue = exVat ? parseFloat(exVat * exchangeRate).toFixed(2) : null;

    onUpdateTransaction(newTransaction);
  };

  const calculateAmount = () => {
    const newTransaction = transaction;

    const amount = (transaction.vat.rate && !transaction.vat.isReverseCharge) ? transaction?.exVat * (1 + (transaction.vat.rate / 100)) : transaction.exVat;
    const vatAmount = parseFloat(amount) - parseFloat(transaction.exVat);

    newTransaction.vatAmount = vatAmount ? parseFloat(vatAmount).toFixed(2) : '';
    newTransaction.amount = amount ? parseFloat(amount).toFixed(2) : '';

    onUpdateTransaction(newTransaction);
  };

  const hide = transaction.destroy === 1 ? 'hide' : '';

  return (
    <tr className={hide}>
      <DropdownTableField
        className="min-width-2"
        type="fetch"
        entities={[]}
        fieldName="account"
        dataTh="Account"
        updateDropdownVoucher={onUpdateTransaction}
        transaction={attachTransaction}
        placeholder="Select account"
        preview
        setIsLoading={setIsLoadingAccount}
        editable={editable}
        errors={errors}
        integrationType={integrationType}
        voucherType={voucherType}
        defaultValue={transaction.account?.id}
      />
      <DropdownTableField
        className="min-width-1"
        type="fetch"
        entities={[]}
        fieldName="vat"
        dataTh="Vat"
        updateDropdownVoucher={onUpdateAmountOrVat}
        transaction={attachTransaction}
        placeholder="Select tax account"
        preview
        setIsLoading={setIsLoadingVat}
        editable={editable}
        errors={errors}
        integrationType={integrationType}
        defaultValue={transaction.account?.taxCodeExternalId || transaction.account?.lastTaxCodeId}
      />
      {
        [INTEGRATION_TYPES.XERO, INTEGRATION_TYPES.QUICKBOOKS, 'manual'].includes(integrationType)
          ? (trackingCategories.map(ent => (integrationType !== INTEGRATION_TYPES.QUICKBOOKS || (enableClass && ent.name === 'Class') || (enableCustomer && ent.name === 'Customer/Project') ? (
            <DropdownTableField
              className="min-width-1"
              key={ent.uuid}
              type="post"
              entities={ent.tracking_options}
              fieldName="trackingOptions"
              dataTh={ent.name}
              updateDropdownVoucher={onUpdateTransaction}
              transaction={attachTransaction}
              placeholder="Select tracking option"
              preview
              setIsLoading={setIsLoadingVat}
              editable={editable}
              errors={errors}
              integrationType={integrationType}
            />
          ) : null)))
          : null
      }
      <TableInputField
        className=""
        dataTh="Description"
        fieldName="description"
        transaction={attachTransaction}
        onUpdateTransaction={onUpdateTransaction}
        placeholder="Type description"
        preview
        editable={editable}
        onKeyDown={e => (e.key === 'Enter' && e.preventDefault())}
      />
      <TableInputField
        className="number price"
        dataTh="Amount"
        fieldName="amount"
        transaction={attachTransaction}
        onUpdateTransaction={onUpdateAmountOrVat}
        numberField
        placeholder="0.00"
        editable={editable}
        errors={errors}
        displayValue={I18n.toNumber(attachTransaction.amount, { delimiter: ' ', precision: 2 })}
      />
      <TableInputField
        className="number price column-fit"
        dataTh="Vat amount"
        fieldName="vatAmount"
        transaction={attachTransaction}
        onUpdateTransaction={onUpdateTransaction}
        numberField
        placeholder="0.00"
        editable={false}
        displayValue={I18n.toNumber(attachTransaction.vatAmount, { delimiter: ' ', precision: 2 })}
      />
      <TableInputField
        className="number price column-fit"
        dataTh="Ex vat"
        fieldName="exVat"
        transaction={attachTransaction}
        onUpdateTransaction={onUpdateExVat}
        numberField
        placeholder="0.00"
        editable={editable}
        displayValue={I18n.toNumber(attachTransaction.exVat, { delimiter: ' ', precision: 2 })}
      />
      <TableInputField
        className="number price"
        dataTh="Po amount"
        fieldName="poAmount"
        transaction={attachTransaction}
        onUpdateTransaction={onUpdateTransaction}
        numberField
        placeholder="0.00"
        editable={editable}
        displayValue={I18n.toNumber(attachTransaction.poAmount, { delimiter: ' ', precision: 2 })}
      />
      <td className="mark">
        {attachTransaction.isAssigned()
          ? (
            <React.Fragment>
              <p className="color-green small">
                {attachTransaction.isAssignedToBudget()
                  ? (
                    <React.Fragment>
                      {`${I18n.t('purchase_orders.invoices.linked')}:`}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {`${I18n.t('purchase_orders.invoices.matched')}:`}
                    </React.Fragment>
                  )
                }
              </p>
              <p>{attachTransaction.purchaseOrderNumber || attachTransaction.categoryName}</p>
              <p className="small">{attachTransaction.budgetName}</p>
            </React.Fragment>
          ) : null
        }
      </td>
      <td className="actions mark">
        {attachTransaction.purchaseOrderNumber
          && (<a onClick={() => togglePoPreviewOpen(attachTransaction.purchaseOrderId)} target="blank" className="icon-file-preview" />)
        }
        <TransactionRowActions
          onAttachToPurchaseOrder={onAttachToPurchaseOrder}
          onAttachToBudget={onAttachToBudget}
          transaction={attachTransaction}
          onDetach={onDetach}
          state={state}
          onUpdateTransaction={onUpdateTransaction}
          currentUser={currentUser}
          setAttachInfoModalOpen={setAttachInfoModalOpen}
        />
      </td>
      <td className="actions mark on-table">
        {removable
          ? (
            <span>
              <a onClick={() => { removeTransaction(attachTransaction); }} className="on-hover remove">
                <i className="icon-delete_forever" />
                <span className="mobie-inline" />
              </a>
            </span>
          ) : null
        }
      </td>
    </tr>
  );
}

export default TransactionRow;
