import React from 'react';

class FilterBar extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      searchValue: '',
    };
  }

  onSearch = (e) => {
    if (this.timer) clearTimeout(this.timer);

    this.setState({ searchValue: e.target.value.replace('%', '') }, () => {
      this.timer = setTimeout(() => {
        const { state: { searchValue }, props: { onSearchQuery } } = this;
        onSearchQuery(searchValue);
      }, 500);
    });
  }

  render() {
    const {
      props: {
        categoriesFilterOptions, currentCategoryFilter, setCategoryFilter,
        groupsFilterOptions, currentGroupFilter, setGroupFilter,
      },
      state: {
        searchValue,
      },
    } = this;
    return (
      <div className="table-actions">
        <div className="filters t-under-hide">
          <span className="select input-xs filter-select can-unselect">
            <input type="checkbox" />
            <div className="select-content">
              {
                categoriesFilterOptions.map(cateogry => (
                  <React.Fragment key={cateogry.id}>
                    <input
                      autoComplete="off"
                      id={`webfilter_project_${cateogry.id}`}
                      type="radio"
                      checked={currentCategoryFilter.id === cateogry.id}
                    />
                    <label onClick={() => setCategoryFilter(cateogry)}>{cateogry.name}</label>
                  </React.Fragment>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.categories')} />
            </div>
          </span>
          <span className="select input-xs filter-select can-unselect">
            <input type="checkbox" />
            <div className="select-content">
              {
                groupsFilterOptions.map(group => (
                  <React.Fragment key={group.id}>
                    <input
                      autoComplete="off"
                      id={`webfilter_project_${group.id}`}
                      type="radio"
                      checked={currentGroupFilter.id === group.id}
                    />
                    <label onClick={() => setGroupFilter(group)}>{group.name}</label>
                  </React.Fragment>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.groups')} />
            </div>
          </span>
        </div>
        <div className="right-actions">
          <div className="items items-divided">
            <div className="control icon-search input-search left-icon">
              <input
                className="input-s width-2"
                id="items_search"
                placeholder={I18n.t('search')}
                type="text"
                value={searchValue}
                onChange={this.onSearch}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterBar;
