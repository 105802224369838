import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { parseDate } from '@/tools/index';
import { humanizedPrice } from '../shared/number_converter';

export default function TransactionList({
  purchaseOrder: { invoices, currency },
  totalTransactionsValue,
  totalTransactionsCurrencyValue,
  deviation,
  inOneCurrencyDeviation,
  allInvoicesInOneCurrency,
  companyCurrency,
}) {
  const [transactionsListVisible, setTransactionsListVisible] = useState(true);

  const toggleTransactionList = () => setTransactionsListVisible(!transactionsListVisible);

  return (
    <div className="table-collapse">
      <div className="table-header">
        <span>{I18n.t('purchase_order_preview.transaction_list')}</span>
        <span
          className={`icon-keyboard_arrow_${transactionsListVisible ? 'up' : 'down'}`}
          onClick={toggleTransactionList}
        />
      </div>
      {transactionsListVisible && (
        <table className="table-tiny">
          <thead>
            <tr>
              <th>{I18n.t('purchase_order_preview.date')}</th>
              <th>{I18n.t('purchase_order_preview.invoice_no')}</th>
              <th>{I18n.t('purchase_order_preview.reference')}</th>
              <th>{I18n.t('purchase_order_preview.description')}</th>
              <th className="price number">{I18n.t('purchase_order_preview.value')}</th>
              <th>{I18n.t('purchase_order_preview.currency')}</th>
              <th className="price number">{I18n.t('purchase_order_preview.currency_value', { currency: companyCurrency })}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {invoices.map(transaction => (
              <tr key={uuid()}>
                <td className="has-ellipsis w-20" data-ellipsis={transaction.updated_at}>
                  <div className="text-ellipsis">{parseDate(transaction.updated_at, 'dd.MM.yyyy')}</div>
                </td>
                <td>
                  <a className="link color-blue" href="">
                    {transaction.doc_number}
                  </a>
                </td>
                <td className="has-ellipsis w-20" data-ellipsis={transaction.voucher_reference}>
                  <div className="text-ellipsis">{transaction.voucher_reference}</div>
                </td>
                <td className="has-ellipsis w-20" data-ellipsis={transaction.description}>
                  <div className="text-ellipsis">{transaction.description}</div>
                </td>
                <td className="number price">{humanizedPrice(transaction.currency_value)}</td>
                <td>{transaction.currency}</td>
                <td className="number price">{humanizedPrice(transaction.value)}</td>
                <td className="align-right padding-left-4">
                  <a target="_blank" href={transaction.pdf_path} className="icon-file-preview" />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="4" className="td number">
                {I18n.t('purchase_order_preview.total_value')}
              </th>
              <td className="number price bold total">{allInvoicesInOneCurrency && humanizedPrice(totalTransactionsCurrencyValue)}</td>
              <td>{allInvoicesInOneCurrency && currency}</td>
              <td className="number price bold total">{humanizedPrice(totalTransactionsValue)}</td>
              <td />
            </tr>
            <tr>
              <th colSpan="4" className="td number">
                {I18n.t('purchase_order_preview.deviation')}
              </th>
              <td className="number price bold total">{allInvoicesInOneCurrency && humanizedPrice(inOneCurrencyDeviation)}</td>
              <td />
              <td className="number price bold total">{humanizedPrice(deviation)}</td>
              <td />
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  );
}
