import _ from 'lodash';
import Model from './model';

class ColumnViewModel extends Model {
  constructor({ ...attributes }) {
    super(attributes);
  }

  toParams = (type, customFieldSettings) => {
    switch (type) {
      case 'purchase_order':
        return this.assignPurchaseOrderAttributes(customFieldSettings);
      case 'delivery':
        return this.deliveryAttributes();
      default:
        return this.purchaseOrderItemAttributes();
    }
  }

  assignPurchaseOrderAttributes(customFieldSettings) {
    const attributes = this.purchaseOrderAttributes();
    customFieldSettings.map(object => (
      attributes[object.slug] = this[_.camelCase(object.slug)]
    ));
    return attributes;
  }

  purchaseOrderAttributes = () => ({
    po_number: this.poNumber,
    parent_po: this.parentPo,
    date_raised: this.dateRaised,
    description: this.description,
    delivery_date: this.deliveryDate,
    expected_delivery_date: this.expectedDeliveryDate,
    confirmed_delivery_date: this.confirmedDeliveryDate,
    customer: this.customer,
    purchase_type: this.purchaseType,
    sales_order: this.salesOrder,
    account: this.account,
    supplier: this.supplier,
    budget: this.budget,
    po_value: this.poValue,
    currency: this.currency,
    department: this.department,
    po_value_in_currency: this.poValueInCurrency,
    not_invoiced: this.notInvoiced,
    invoiced: this.invoiced,
    committed: this.committed,
    project: this.project,
    status: this.status,
    created_by: this.createdBy,
    approved_by: this.approvedBy,
    comments: this.comments,
  })

  purchaseOrderItemAttributes = () => ({
    po_number: this.poNumber,
    raised: this.raised,
    supplier_name: this.supplierName,
    budget_name: this.budgetName,
    category_name: this.categoryName,
    number: this.number,
    description: this.description,
    quantity: this.quantity,
    unit: this.unit,
    ordered_value: this.orderedValue,
    currency: this.currency,
    ordered_currency_value: this.orderedCurrencyValue,
    received_quantity: this.receivedQuantity,
    received_value: this.receivedValue,
    state: this.state,
    price: this.price,
  })

  deliveryAttributes = () => ({
    delivery_date: this.deliveryDate,
    owner_number: this.ownerNumber,
    sku: this.sku,
    name: this.name,
    quantity: this.quantity,
    po_number: this.poNumber,
    raised: this.raised,
    po_delivery_date: this.poDeliveryDate,
    supplier_name: this.supplierName,
    budget_name: this.budgetName,
    unit: this.unit,
    ordered_value: this.orderedValue,
    ordered_currency_value: this.orderedCurrencyValue,
    good_items_count: this.goodItemsCount,
    bad_items_count: this.badItemsCount,
    received_quantity: this.receivedQuantity,
    received_value: this.receivedValue,
    received_currency_value: this.receivedCurrencyValue,
    state: this.state,
    user_name: this.userName,
    total_received: this.totalReceived,
    not_received: this.notReceived,
  })

  purchaseOrderColumns = (currentCompany, projectSettings, customFieldSettings, accountSettings) => {
    let columns = [
      'po_number', 'date_raised', 'delivery_date', 'expected_delivery_date', 'confirmed_delivery_date', 'supplier',
      'budget', 'description', 'department', 'po_value', 'currency', 'po_value_in_currency',
      'not_invoiced', 'invoiced', 'committed', 'comments', 'status', 'created_by', 'approved_by',
    ];

    if (currentCompany.parentPoEnabled) columns.push('parent_po');
    if (currentCompany.accountsEnabled && accountSettings && accountSettings.active) columns.push('account');
    if (currentCompany.customPoEnabled) {
      columns = columns.concat(['customer', 'purchase_type', 'sales_order']);
    }
    if (projectSettings && projectSettings.active) columns.push('project');
    if (customFieldSettings) customFieldSettings.map(field => columns.push(`custom_field_setting_${field.id}`));

    return columns;
  };

  purchaseOrderItemColumns = () => (
    [
      'po_number', 'raised', 'supplier_name', 'budget_name', 'category_name', 'number',
      'description', 'quantity', 'price', 'unit', 'currency', 'ordered_currency_value', 'ordered_value', 'received_quantity',
      'received_value', 'state',
    ]
  );

  deliveryColumns = () => (
    [
      'delivery_date', 'owner_number', 'sku', 'name', 'quantity', 'po_number', 'raised',
      'po_delivery_date', 'supplier_name', 'budget_name', 'unit', 'ordered_value',
      'ordered_currency_value', 'good_items_count', 'bad_items_count', 'received_quantity',
      'received_value', 'received_currency_value', 'state', 'total_received', 'not_received',
      'user_name',
    ]
  );
}

export default ColumnViewModel;
