import React from 'react';
import CellProgressBar from '@/components/ui/table/cell_progress_bar';
import { parseDate } from '@/tools';
import Warning from '@/icons/Warning';
import Tooltip from '@/components/uiElements/Tooltip';
import useTooltip from '@/hooks/useTooltip';
import Comment from '@/icons/Comment';

export default function PurchaseOrderRow({
  purchaseOrder, onToggleAttachPoSettings, ableToAttach, onBulkSelect,
}) {
  const [isTooltipOpen, tooltipTriggerRef] = useTooltip();
  const openAttachPoSettings = (recommended) => { onToggleAttachPoSettings(purchaseOrder.id, recommended); };
  const purchaseOrderUrl = `/purchase_orders/${purchaseOrder.id}/edit?list=invoice`;
  const receivedPercentage = (parseFloat(purchaseOrder.totalReceivedValue) / parseFloat(purchaseOrder.totalAmountInPoCurrency)) * 100.0 || 0;
  const chartClass = purchaseOrder.state === 'completed' || purchaseOrder.state === 'received' ? 'recived' : 'goods';
  const onToggle = () => onBulkSelect(purchaseOrder);
  const purchaseOrderSelected = purchaseOrder.selected || false;

  return (
    <div className="tr">
      <div className="td min-width-0">
        <label className="has-checkbox">
          <input className="count-selected" type="checkbox" checked={purchaseOrderSelected} onChange={onToggle} />
          <span className="checkbox input-s margin-right-0" />
        </label>
      </div>
      <a href={purchaseOrderUrl} className="td has-ellipsis" data-ellipsis={purchaseOrder.poNumber}>
        <div className="text-ellipsis w-7">{purchaseOrder.poNumber}</div>
      </a>
      <a href={purchaseOrderUrl} className="td has-ellipsis" data-ellipsis={parseDate(purchaseOrder.raisedDate, 'dd.MM.yyyy')}>
        <div className="text-ellipsis w-7">{parseDate(purchaseOrder.raisedDate, 'dd.MM.yyyy')}</div>
      </a>
      <a href={purchaseOrderUrl} className="td has-ellipsis" data-ellipsis={purchaseOrder.supplierName}>
        <div className="text-ellipsis w-7">{purchaseOrder.supplierName}</div>
      </a>
      <a href={purchaseOrderUrl} className="td has-ellipsis" data-ellipsis={purchaseOrder.budgetName}>
        <div className="text-ellipsis w-10">{purchaseOrder.budgetName}</div>
      </a>
      <a href={purchaseOrderUrl} className="td number price bold">{purchaseOrder.totalAmount}</a>
      <a href={purchaseOrderUrl} className="td number price bold">{purchaseOrder.notInvoiced}</a>
      {deviationTd(purchaseOrder.deviation, purchaseOrder.deviationPrice, purchaseOrder.invoiced, purchaseOrderUrl)}
      <a href={purchaseOrderUrl} className="td number price bold">{purchaseOrder.committed}</a>
      <a href={purchaseOrderUrl} className="center td">{purchaseOrder.invoicesCount}</a>
      <a className="td has-charts short">
        <CellProgressBar chartClass={chartClass} valuePercentage={receivedPercentage} tooltip="received" />
      </a>
      <a href={purchaseOrderUrl} className="td has-status">
        <span className={`${purchaseOrder.state} capitalize status`}>{I18n.t(`purchase_orders.form.activities_keys.${purchaseOrder.state}`)}</span>
      </a>
      <a href={purchaseOrderUrl} className="td">
        <div className="avatar approve has-tooltip">
          <img src={purchaseOrder.user.avatar_url} />
          <span className="tooltip center">{purchaseOrder.user.display_name}</span>
        </div>
      </a>
      <a href={purchaseOrderUrl} className="td column-fit nowrap center">
        {purchaseOrder.commentsCount > 0 ? (
          <div className="has-tooltip center" data-tooltip={purchaseOrder.commentsCount}>
            <Comment style={{ width: '24px', height: '24px' }} />
          </div>
        ) : null}
      </a>

      { purchaseOrder.outdated && (
        <div className="td actions ct-relative">

          <a ref={tooltipTriggerRef} href={purchaseOrderUrl}>
            <Warning className="ct-h-5.5 ct-w-5.5 ct-text-orange" />
          </a>

          { isTooltipOpen && (
            <div className="ct-absolute ct-top-1 1300:ct-right-[calc(0.5rem+3px)] ct-right-[calc(1rem+3px)] ct-translate-y-[-100%] ct-z-20">
              <Tooltip isOpen={isTooltipOpen} direction="BOTTOM_RIGHT" text={I18n.t('errors.purchase_order.outdated_title')} className="ct-w-fit" />
            </div>
          )}

        </div>
      )}

      { ableToAttach && !purchaseOrder.outdated
        ? (
          <div className="td actions">
            { purchaseOrder.potentialInvoices
              ? (
                <a className="has-tooltip right color-blue" data-tooltip={I18n.t('purchase_orders.same_user_transaction')}>
                  <i className="icon-notification_important" onClick={() => openAttachPoSettings(true)} />
                </a>
              ) : null
            }
            <label className="icon-add_circle add" htmlFor="attach-to-po-modal" onClick={() => openAttachPoSettings(false)} />
          </div>
        ) : null }
    </div>
  );
}

function deviationTd(deviation, deviationPrice, invoiced, purchaseOrderUrl) {
  if (deviation > 0) {
    return (
      <a href={purchaseOrderUrl} className="td number price bold" data-beyond={deviationPrice}>{invoiced}</a>
    );
  }
  return (
    <a href={purchaseOrderUrl} className="td number price bold">{invoiced}</a>
  );
}
