import * as Api from '../components/shared/api';

class ImportItemsService {
  static create = ({ file, supplierId }) => {
    const formData = new FormData();

    formData.append('supplier_item_file[file]', file);
    formData.append('supplier_item_file[supplier_id]', supplierId);

    return Api.makePostFileRequest('/supplier_items_import', formData)
      .then(response => response.data);
  }
}

export default ImportItemsService;
