import * as moment from 'moment';

class InvoiceModel {
  constructor(attributes) {
    this.id = attributes.id;
    this.source = attributes.source;
    this.voucherPath = attributes.voucher_path;
    this.invoicePdfPath = attributes.invoice_pdf_path;
    this.humanizedSource = attributes.humanized_source;
    this.docDate = attributes.doc_date ? moment.utc(attributes.doc_date) : '';
    this.dueDate = attributes.due_date ? moment.utc(attributes.due_date) : '';
    this.period = attributes.period;
    this.supplierId = attributes.supplier?.id;
    this.supplierName = attributes.supplier?.name;
    this.number = attributes.number;
    this.invoiceNumber = attributes.number || '';
    this.accountNumber = attributes.account_number;
    this.accountName = attributes.account_name;
    this.department = attributes.department_name;
    this.project = attributes.project_name;
    this.description = attributes.description;
    this.voucherReference = attributes.voucher_reference;
    this.value = attributes.value || '';
    this.humanizedValue = attributes.humanized_value;
    this.currencyValue = attributes.currency_value || '';
    this.currency = attributes.currency;
    this.humanizedCurrencyValue = attributes.humanized_currency_value;
    this.poNumber = attributes.po_number;
    this.budgetNumber = attributes.budget_number;
    this.budgetName = attributes.budget_name;
    this.budgetId = attributes.budget_id;
    this.purchaseOrderNumber = attributes.purchase_order_number;
    this.purchaseOrderId = attributes.purchase_order_id;
    this.attachmentUrl = attributes.attachment?.url || attributes.attachment_url;
    this.attachmentName = attributes.attachment?.name || attributes.attachment_name;
    this.transactionCurrency = attributes.currency || '';
    this.attachment = attributes.attachment || '';
    this.automaticallyMatched = attributes.automatically_matched || false;
    this.deletedAt = attributes.deleted_at;
    this.categoryNumber = attributes.category?.number;
    this.categoryName = attributes.category?.name;
    this.supplierNumber = attributes.supplier?.supplier_number;
    this.voucherNumber = attributes.voucher_number;
    this.potentialPurchaseOrders = attributes.potential_purchase_orders;
    this.docNumber = attributes.doc_number;
    this.paid = attributes.paid || false;
    this.outdated = attributes.outdated;
  }

  toFileParams = () => {
    const data = new FormData();
    const attrs = {
      department_name: this.departmentName,
      currency: this.transactionCurrency,
      doc_date: this.docDate,
      due_date: this.dueDate,
      number: this.invoiceNumber,
      account_name: this.accountName,
      project_name: this.project,
      description: this.description,
    };

    if (this.id) attrs.id = this.id;
    if (this.purchaseOrderId) attrs.purchase_order_id = this.purchaseOrderId;
    if (this.supplierId) attrs.supplier_id = this.supplierId;
    if (this.value) attrs.value = this.value;
    if (this.currencyValue) attrs.currency_value = this.currencyValue;

    for (const [key, value] of Object.entries(attrs)) {
      if (value !== undefined && value !== null) data.append(`invoice[${key}]`, value);
    }

    data.append('invoice[attachment]', this.attachment);
    data.append('id', this.id);

    return data;
  }

  toUnattachParams = () => ({
    purchase_order_id: null,
  })

  isManual = () => this.source === 'manual';

  isInvoice = () => this.source === 'invoice';

  isAssigned = () => this.purchaseOrderNumber || this.budgetId;

  isAssignedToBudget = () => this.budgetId;

  formattedDocDate = () => {
    if (this.docDate) {
      return this.docDate.format('DD.MM.YYYY');
    }
    return '';
  }

  formattedDueDate = () => {
    if (this.dueDate) {
      return this.dueDate.format('DD.MM.YYYY');
    }
    return '';
  }

  docDateOrDefaultDate = () => {
    if (this.id) {
      return this.docDate || null;
    }
    return this.docDate || moment(new Date());
  }

  defaultDate = (date) => {
    if (this.id) {
      return this[date] || null;
    }

    return this[date] || moment(new Date());
  }

  fullAccountName = () => {
    if (!this.accountNumber && this.accountName) {
      return `${this.accountName}`;
    }
    if (this.accountName && this.accountNumber) {
      return `${this.accountNumber} - ${this.accountName}`;
    }
    if (!this.accountName && !this.accountNumber) {
      return '';
    }
    return this.accountNumber;
  }

  fullSupplierName = () => {
    if (this.supplierNumber) {
      return `${this.supplierName} (${this.supplierNumber})`;
    }
    return this.supplierName;
  }
}

export default InvoiceModel;
