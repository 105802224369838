import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import _ from 'lodash';
import * as toast from './shared/toast';
import adminsAvatar from '../../assets/images/admins_avatar.png';
import PurchaseOrderService from '../services/purchase_orders_service';
import ItemList from './purchase_order_preview/item_list';
import TransactionList from './purchase_order_preview/transaction_list';
import CommentsSection from './purchase_order_preview/comments_section';

export default function PurchaseOrderPreview({ purchaseOrderId, togglePoPreviewOpen, companyCurrency }) {
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  useEffect(() => {
    fetchPurchaseOrder();
  }, [purchaseOrderId]);

  const fetchPurchaseOrder = () => {
    PurchaseOrderService.find(purchaseOrderId)
      .then((object) => {
        setPurchaseOrder(object);
      })
      .catch(() => {
        toast.errorToast(I18n.t('commons.messages.error'));
      });
  };

  const totalItemsValue = () => purchaseOrder.items.reduce((sum, item) => sum + item.quantity * item.price, 0);

  const totalItemsCurrencyValue = () => totalItemsValue() * purchaseOrder.exchangeRate;

  const totalTransactionsValue = () => purchaseOrder.invoices.reduce((sum, transaction) => sum + parseFloat(transaction.value), 0);

  const totalTransactionsCurrencyValue = () => purchaseOrder.invoices.reduce((sum, transaction) => sum + parseFloat(transaction.currency_value), 0);

  const deviation = () => (allInvoicesInOneCurrency()
    ? totalItemsCurrencyValue() - totalTransactionsCurrencyValue() * purchaseOrder.exchangeRate
    : totalItemsCurrencyValue() - totalTransactionsValue());

  const inOneCurrencyDeviation = () => totalItemsValue() - totalTransactionsCurrencyValue();

  const allInvoicesInOneCurrency = () => {
    const currencies = _.uniq(purchaseOrder.invoices.map(({ currency }) => currency));
    return currencies.length === 1 && currencies[0] === purchaseOrder.currency;
  };

  return (
    purchaseOrder && (
      <div className="window-popover window">
        <div className="window-popover-header window-header">
          <div className="h3 margin-0">
            <span className="margin-right-2">{purchaseOrder.poNumber}</span>
            <span className="status status-small sent_to_supplier">
              {I18n.t(`purchase_orders.statuses.${purchaseOrder.state}`)}
            </span>
          </div>
          <a className="link" href={`/purchase_orders/${purchaseOrderId}/edit`} target="blank">
            {I18n.t('purchase_order_preview.po_details')}
          </a>
          <a className="link" href={`/purchase_orders/${purchaseOrderId}.pdf`} target="blank">
            {I18n.t('purchase_order_preview.view_pdf')}
          </a>
          <a className="popover-close icon-close" onClick={() => togglePoPreviewOpen(null)} />
        </div>
        <div className="window-popover-content">
          <div className="show-inline">
            <div className="inline">
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_order_preview.supplier')}</span>
                <div className="output padding-0 padding-top-1">{purchaseOrder.supplierNameWithNumber}</div>
              </div>
            </div>
            <div className="inline">
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_order_preview.delivery_date')}</span>
                <div className="output  padding-0 padding-top-1">{purchaseOrder.deliveryDate}</div>
              </div>
            </div>
            <div className="inline">
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_order_preview.raised_and_approved')}</span>
                <div className="output  padding-0 padding-top-1 with-avatars">
                  <div className="avatars first-arrow">
                    <span className="avatar small has-tooltip" tabIndex="-1">
                      <img src={purchaseOrder.userAvatar} />
                      <span className="tooltip bottom-center">{purchaseOrder.userFullName}</span>
                    </span>
                    {purchaseOrder.approveActions.map(action => (
                      <span className={`avatar small has-tooltip ${action.state}`} key={uuid()} tabIndex="-1">
                        {action.users.length > 1 ? (
                          <img src={adminsAvatar} />
                        ) : (
                          <img src={action.users[0].avatar_url} />
                        )}
                        <span className="tooltip bottom-center">
                          {action.users.map(user => (
                            <li key={uuid()}>{user.display_name}</li>
                          ))}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="inline">
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_order_preview.budget')}</span>
                <div className="output padding-0 padding-top-1">{purchaseOrder.budgetDisplayName}</div>
              </div>
            </div>
            <div className="inline">
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_order_preview.account')}</span>
                <div className="output  padding-0 padding-top-1">{purchaseOrder.accountName}</div>
              </div>
            </div>
            <div className="inline">
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_order_preview.received')}</span>
                <div className="output  padding-0 padding-top-2 has-charts short">
                  <div className="chart-inside has-tooltip">
                    <div className="charts">
                      <div
                        className={`chart goods ${
                          ['completed', 'received'].includes(purchaseOrder.state) ? 'recived' : 'goods'
                        }`}
                        style={{ width: `${purchaseOrder.receivedPercentage}%` }}
                      >
                        <span />
                      </div>
                      <div
                        className="chart has-tooltip null"
                        style={{ width: `${100 - purchaseOrder.receivedPercentage}%` }}
                      >
                        <span />
                      </div>
                    </div>
                    <div className="tooltip">
                      {`${I18n.toNumber(
                        Math.round(purchaseOrder.receivedPercentage > 100.0 ? 100.0 : purchaseOrder.receivedPercentage),
                        { delimiter: ' ', precision: 1 },
                      )}% received`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="window-popover-tables">
          <ItemList
            purchaseOrder={purchaseOrder}
            companyCurrency={companyCurrency}
            totalItemsValue={totalItemsValue()}
            totalItemsCurrencyValue={totalItemsCurrencyValue()}
          />
          <TransactionList
            purchaseOrder={purchaseOrder}
            companyCurrency={companyCurrency}
            totalTransactionsValue={totalTransactionsValue()}
            totalTransactionsCurrencyValue={totalTransactionsCurrencyValue()}
            deviation={deviation()}
            inOneCurrencyDeviation={inOneCurrencyDeviation()}
            allInvoicesInOneCurrency={allInvoicesInOneCurrency()}
          />
          <CommentsSection activities={purchaseOrder.activities} />
        </div>
      </div>
    )
  );
}
