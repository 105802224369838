import React from 'react';

class AttachInvoiceOrderRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceAttached: props.invoice.purchaseOrderId === props.purchaseOrderId,
    };
  }

  onToggle = () => {
    const { invoiceAttached } = this.state;
    const { invoice, onDisattach, onAttach } = this.props;

    if (invoiceAttached) {
      onDisattach(invoice.id);
    } else {
      onAttach(invoice.id);
    }

    this.setState({
      invoiceAttached: !invoiceAttached,
    });
  }

  render() {
    const { invoice, currency } = this.props;
    const { invoiceAttached } = this.state;

    return (
      <div className="manual tr">
        <input id={`row_${invoice.id}`} type="checkbox" onChange={this.onToggle} checked={invoiceAttached} />
        <div className="td">
          <label className="has-checkbox" htmlFor={`row_${invoice.id}`}>
            <span className="icon-add_circle checkbox-green" />
          </label>
          {invoice.formattedDocDate()}
        </div>
        <div className="td has-ellipsis link" data-ellipsis={invoice.fullSupplierName()}>
          <a className="link text-ellipsis w-10" href={`/suppliers/${invoice.supplierId}`}>{invoice.fullSupplierName()}</a>
        </div>
        <div className="td">{invoice.poNumber}</div>
        <div className="td">{invoice.voucherNumber}</div>
        <div className="td">{invoice.number}</div>
        <div className="td has-ellipsis" data-ellipsis={invoice.fullAccountName()}>
          <div className="text-ellipsis w-7">{invoice.fullAccountName()}</div>
        </div>
        <div className="td has-ellipsis" data-ellipsis={invoice.project}>
          <div className="text-ellipsis w-7">{invoice.project}</div>
        </div>
        <div className="td has-ellipsis" data-ellipsis={invoice.description}>
          <div className="text-ellipsis w-10">{invoice.description}</div>
        </div>
        <div className="td number bold" data-currency={invoice.transactionCurrency !== currency ? invoice.humanizedCurrencyValue : ''}>{invoice.humanizedValue}</div>
        <div className="td actions min-width-0">
          { invoice.attachmentUrl ? <a className="icon-insert_file color-mute" href={invoice.attachmentUrl} target="blank" /> : null }
        </div>
      </div>
    );
  }
}

export default AttachInvoiceOrderRow;
