import React from 'react';

const BulkActionBar = ({
  selectedItemsCount, destroyAll, restoreAll, state, onSelectAllBulkAction,
}) => (
  <div className="table-bulk" style={{ display: 'flex' }}>
    <div className="actions">
      <div className="items items-divided">
        <span>
          <span className="selected" data-selected="selected">
            {`${selectedItemsCount} Selected`}
          </span>
        </span>
        <span>
          <span className="select input-s select-transparent display-inline-block">
            <input type="checkbox" />
            <div className="select-content">
              {
                state.name === 'inactive'
                  ? <label onClick={restoreAll}>{I18n.t('supplier_items.restore')}</label>
                  : <label onClick={destroyAll}>{I18n.t('supplier_items.remove')}</label>
              }
              <div className="placeholder" data-placeholder="Bulk actions" />
            </div>
          </span>
        </span>
      </div>
    </div>
    <label className="icon-close" htmlFor="table-bulk" onClick={onSelectAllBulkAction} />
  </div>
);

export default BulkActionBar;
