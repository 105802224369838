import React from 'react';
import DropdownsService from '../../../services/dropdowns_service';

class MultiselectDropdownTableField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entities: props.entites,
      values: props.transaction[props.fieldName].map(ent => ent.id) || [],
    };
  }

  componentDidMount() {
    const {
      type, setIsLoading, integrationType,
    } = this.props;

    if (type === 'fetch') {
      setIsLoading(true);

      DropdownsService.getEnt('project', '', integrationType)
        .then((response) => {
          this.setState(
            {
              entities: response,
            },
          );
          setIsLoading(false);
        });
    }
  }

  checked = (ent) => {
    const { values } = this.state;
    return values.some(entId => ent.id === entId);
  }

  onChange = ({ target: { id } }) => {
    const {
      props: { updateDropdownVoucher, transaction, fieldName },
      state: { entities },
    } = this;
    const newTransaction = transaction;
    const selected = entities.find(ent => ent.id === parseInt(id));

    if (this.checked(selected)) {
      newTransaction[fieldName] = transaction[fieldName].map((ent) => {
        const newEnt = ent;
        if (ent.id === selected.id) {
          newEnt.destroy = 1;
          return newEnt;
        }
        return ent;
      });
    } else {
      selected.destroy = 0;
      newTransaction[fieldName].push(selected);
    }

    this.setState({ values: newTransaction[fieldName].filter(ent => ent.destroy === 0).map(ent => ent.id) });
    updateDropdownVoucher(newTransaction);
  }

  removeDuplicates = myArr => myArr.filter(
    (obj, pos, arr) => arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos,
  )

  render() {
    const {
      props: {
        dataTh, placeholder, errors, fieldName, transaction,
      },
      state: {
        entities,
      },
    } = this;

    const hasErrorClass = errors && errors[fieldName] ? 'has-error' : '';

    const allEntiteis = this.removeDuplicates([...transaction[fieldName], ...entities]).filter(ent => ent.id !== '');

    return (
      <td data-th={dataTh} className={`${hasErrorClass}`}>
        <span>
          <span className="select auto-position auto-content-width input-table input-r can-unselect count-selected">
            <input type="checkbox" />
            <div className="select-content ">
              {
                allEntiteis.map(ent => (
                  <React.Fragment>
                    <label
                      className={this.checked(ent) ? 'checked' : ''}
                      id={ent.id}
                      name={ent.dispValues[0]}
                      onClick={this.onChange}
                    >
                      {ent.dispValues[0]}
                    </label>
                  </React.Fragment>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={placeholder} />
            </div>
          </span>
          { hasErrorClass
            ? (
              <div className="error">
                { errors[fieldName].map(er => (
                  <div key={er} className="hint error">{er}</div>
                )) }
              </div>
            ) : null
          }
        </span>
      </td>
    );
  }
}

export default MultiselectDropdownTableField;
