import React from 'react';

class MobileFiltersModal extends React.Component {
  render() {
    const {
      props: {
        statesFilterOptions, currentStateFilter, setStateFilter,
        suppliersFilterOptions, currentSupplierFilter, setSupplierFilter,
        categoriesFilterOptions, currentCategoryFilter, setCategoryFilter,
        groupsFilterOptions, currentGroupFilter, setGroupFilter, onToggleMobileFiltersModal,
        isMobileFilterModalOpen,
      },
    } = this;
    return (
      <div className={`has-filter has-popup mobile-filter ${isMobileFilterModalOpen ? 'active' : null}`}>
        <div className={`popup ${isMobileFilterModalOpen ? 'active' : null}`}>
          <div className="popup-content">
            <h3>{I18n.t('purchase_orders.filter_by')}</h3>
            <div className="grid space-2 grid-form">
              <div className="row">
                <div className="cell">
                  <span key="states" className="select input-xs filter-select can-unselect">
                    <input type="checkbox" />
                    <div className="select-content ">
                      {
                        statesFilterOptions.map(state => (
                          <React.Fragment key={state.id}>
                            <input
                              autoComplete="off"
                              id={`webfilter_project_${state.id}`}
                              type="radio"
                              checked={currentStateFilter.id === state.id}
                            />
                            <label onClick={() => setStateFilter(state)}>{state.name}</label>
                          </React.Fragment>
                        ))
                      }
                      <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.states')} />
                    </div>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <span key="supplier" className="select input-xs filter-select can-unselect">
                    <input type="checkbox" />
                    <div className="select-content ">
                      {
                        suppliersFilterOptions.map(supplier => (
                          <React.Fragment key={supplier.id}>
                            <input
                              autoComplete="off"
                              id={`webfilter_project_${supplier.id}`}
                              type="radio"
                              checked={currentSupplierFilter.id === supplier.id}
                            />
                            <label onClick={() => setSupplierFilter(supplier)}>{supplier.name}</label>
                          </React.Fragment>
                        ))
                      }
                      <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.supplier')} />
                    </div>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <span key="categories" className="select input-xs filter-select can-unselect">
                    <input type="checkbox" />
                    <div className="select-content ">
                      {
                        categoriesFilterOptions.map(cateogry => (
                          <React.Fragment key={cateogry.id}>
                            <input
                              autoComplete="off"
                              id={`webfilter_project_${cateogry.id}`}
                              type="radio"
                              checked={currentCategoryFilter.id === cateogry.id}
                            />
                            <label onClick={() => setCategoryFilter(cateogry)}>{cateogry.name}</label>
                          </React.Fragment>
                        ))
                      }
                      <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.categories')} />
                    </div>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <span key="groups" className="select input-xs filter-select can-unselect">
                    <input type="checkbox" />
                    <div className="select-content ">
                      {
                        groupsFilterOptions.map(group => (
                          <React.Fragment key={group.id}>
                            <input
                              autoComplete="off"
                              id={`webfilter_project_${group.id}`}
                              type="radio"
                              checked={currentGroupFilter.id === group.id}
                            />
                            <label onClick={() => setGroupFilter(group)}>{group.name}</label>
                          </React.Fragment>
                        ))
                      }
                      <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.groups')} />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <label className="popup-backdrop" onClick={onToggleMobileFiltersModal} />
        </div>
      </div>
    );
  }
}

export default MobileFiltersModal;
