import React, { useState, useEffect } from 'react';
import TransactionRow from './transaction_row';
import VoucherTransactionModel from '../../models/voucher_transaction_model';
import Loader from '../loader';
import Errors from '../../models/errors';
import DropdownsService from '../../services/dropdowns_service';
import { INTEGRATION_TYPES } from '../../config/constants';

function TransactionsSection({
  errors, voucherErrors, voucher, saveVoucher, updateVoucher,
  onAttachToPurchaseOrder, onAttachToBudget, currentUser,
  integrationType, togglePoPreviewOpen, setAttachInfoModalOpen,
  enableClass, enableCustomer,
}) {
  const [trackingCategories, setTrackingCategories] = useState([]);
  const [isLoadinTrackingCategories, setIsLoadingTrackingCategories] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const [isLoadingVat, setIsLoadingVat] = useState(false);
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(false);

  useEffect(() => {
    getTrackingOptions();
  }, []);

  const onUpdateTransaction = (newTransaction, callback = () => { }) => {
    const newVoucher = voucher;
    newVoucher.transactions = voucher.transactions.map((transaction) => {
      if (transaction.uuid === newTransaction.uuid) {
        return newTransaction;
      }
      return transaction;
    });

    updateVoucher(newVoucher, callback);
  };

  const addNewTransaction = () => {
    const newVoucher = voucher;
    newVoucher.transactions.push(new VoucherTransactionModel({}));

    if (voucher.state !== 'draft') {
      updateVoucher(newVoucher);
    } else {
      updateVoucher(newVoucher, saveVoucher);
    }
  };

  const removeTransaction = (deletedTransaction) => {
    const newVoucher = voucher;
    newVoucher.transactions = voucher.transactions.map((transaction) => {
      const newTransaction = transaction;
      if (transaction.uuid === deletedTransaction.uuid) {
        newTransaction.destroy = 1;
        return newTransaction;
      }
      return transaction;
    });

    updateVoucher(newVoucher, saveVoucher);
  };

  const transactionsSum = (fieldName) => {
    const { transactions } = voucher;
    const sum = transactions
      .filter(transaction => transaction[fieldName] !== '')
      .map(transaction => parseFloat(transaction[fieldName]).toFixed(2))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    return parseFloat(sum).toFixed(2);
  };

  const balance = () => {
    const value = voucher.amount - transactionsSum('amount');

    return parseFloat(value).toFixed(2);
  };

  const removable = () => {
    const { transactions } = voucher;

    return transactions
      .filter(transaction => transaction.destroy !== 1)
      .length !== 1;
  };

  const assignErrors = (id) => {
    if (id == '' && errors.length) {
      const err = errors.find(el => el.id === null);
      return new Errors(err ? err.errors : {});
    }
    if (errors.length) {
      const err = errors.find(el => el.id === id);
      return new Errors(err ? err.errors : {});
    }

    return new Errors({});
  };

  const getTrackingOptions = () => {
    if ([INTEGRATION_TYPES.XERO, INTEGRATION_TYPES.QUICKBOOKS, 'manual'].includes(integrationType)) {
      setIsLoadingTrackingCategories(true);

      DropdownsService.getEnt('tracking_category', '', integrationType, 'transaction')
        .then((response) => {
          setTrackingCategories(response);
          setIsLoadingTrackingCategories(false);
        });
    }
  };

  const htmlClasses = isLoadingAccount && isLoadingVat && isLoadingDepartment && isLoadingProject && isLoadinTrackingCategories ? ' has-loading' : '';

  const hasErrorClass = voucherErrors && voucherErrors.balance ? 'has-error' : '';

  return (
    <div className="form-section cell-12 with-table">
      <div className="form-section-header">
        <span>
          {I18n.t('vouchers.edit.transaction_section_header')}
        </span>
      </div>
      <Loader />
      <div className={`form-section-content ${htmlClasses} ct-mt-8`}>
        <div className="table-fluid release-table table-column-10 show-last inside-window">
          <table className="table-transactions">
            <thead>
              <tr>
                <th>{I18n.t('vouchers.edit.transactions_table.account')}</th>
                <th>{I18n.t('vouchers.edit.transactions_table.vat')}</th>
                {
                  [INTEGRATION_TYPES.XERO, INTEGRATION_TYPES.QUICKBOOKS, 'manual'].includes(integrationType)
                    ? (
                      trackingCategories.map(ent => (integrationType !== INTEGRATION_TYPES.QUICKBOOKS || (enableClass && ent.name === 'Class') || (enableCustomer && ent.name === 'Customer/Project') ? (
                        <th key={ent.uuid}>{ent.name}</th>
                      ) : null
                      ))
                    )
                    : null
                }
                <th>{I18n.t('vouchers.edit.transactions_table.description')}</th>
                <th className="number price">
                  {I18n.t('vouchers.edit.transactions_table.amount')}
                </th>
                <th className="number price">
                  {I18n.t('vouchers.edit.transactions_table.vat_amount')}
                </th>
                <th className="number price">
                  {I18n.t('vouchers.edit.transactions_table.ex_vat')}
                </th>
                <th className="number price">
                  {I18n.t('vouchers.edit.transactions_table.po_amount')}
                </th>
                <th>{I18n.t('vouchers.edit.transactions_table.match')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                voucher.transactions.map(transaction => (
                  <TransactionRow
                    errors={assignErrors(transaction.id)}
                    key={transaction.uuid}
                    transaction={transaction}
                    editable={voucher.editable}
                    onUpdateTransaction={onUpdateTransaction}
                    removeTransaction={removeTransaction}
                    setIsLoadingAccount={setIsLoadingAccount}
                    setIsLoadingVat={setIsLoadingVat}
                    setIsLoadingDepartment={setIsLoadingDepartment}
                    setIsLoadingProject={setIsLoadingProject}
                    onAttachToPurchaseOrder={onAttachToPurchaseOrder}
                    onAttachToBudget={onAttachToBudget}
                    state={voucher.state}
                    exchangeRate={voucher.exchangeRate}
                    voucherType={voucher.type?.name}
                    removable={removable()}
                    currentUser={currentUser}
                    integrationType={integrationType}
                    trackingCategories={trackingCategories}
                    togglePoPreviewOpen={togglePoPreviewOpen}
                    setAttachInfoModalOpen={setAttachInfoModalOpen}
                    enableClass={enableClass}
                    enableCustomer={enableCustomer}
                  />
                ))
              }
            </tbody>
            <tfoot>
              <tr className="total">
                <td className="visible">
                  <a onClick={addNewTransaction} className="link">+ Add another transaction</a>
                </td>
                <td className="mobile-hide" />
                {
                  [INTEGRATION_TYPES.XERO, INTEGRATION_TYPES.QUICKBOOKS, 'manual'].includes(integrationType)
                    ? (trackingCategories.map(ent => (integrationType !== INTEGRATION_TYPES.QUICKBOOKS || (enableClass && ent.name === 'Class') || (enableCustomer && ent.name === 'Customer/Project') ? (
                      <td key={ent.name} className="mobile-hide" />
                    ) : null)))
                    : null
                }
                <th className="td number">
                  {I18n.t('vouchers.edit.transactions_table.balance')}
                </th>
                <td className={`total number price ${hasErrorClass}`}>
                  {I18n.toNumber(balance(), { delimiter: ' ', precision: 2 })}
                  {hasErrorClass
                    ? (
                      <div className="error">
                        {voucherErrors.balance.map(er => (<div key={er} className="hint error">{er}</div>))}
                      </div>
                    ) : null
                  }
                </td>
                <td className="padding-right-0">
                  <div className="with-as">
                    <span className="as-th">{I18n.t('vouchers.edit.transactions_table.vat_amount')}</span>
                    <span className="as-td total number price">{I18n.toNumber(transactionsSum('vatAmount'), { delimiter: ' ', precision: 2 })}</span>
                  </div>
                </td>
                <td className="mobile-hide" colSpan="6" />
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TransactionsSection;
