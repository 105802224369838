import uuid from 'uuid/v4';
import AccountModel from './dropdowns_models/account_model';
import DepartmentModel from './dropdowns_models/department_model';
import ProjectModel from './dropdowns_models/project_model';
import VatModel from './dropdowns_models/vat_model';
import TrackingOptionModel from './tracking_option_model';

class VoucherTransactionModel {
  constructor({
    id, account, tax_code, department, project, category_name, category_id,
    description, included_vat, po_amount, value_cents, purchase_order_id,
    purchase_order_number, budget_id, budget_name, tracking_options,
  }) {
    this.id = id || '';
    this.account = new AccountModel(account);
    this.department = new DepartmentModel(department);
    this.project = new ProjectModel(project);
    this.vat = new VatModel(tax_code);
    this.description = description || '';
    this.amount = this.setPriceValue(included_vat);
    this.vatAmount = 0;
    this.exVat = this.setPriceValue(value_cents);
    this.poAmount = this.setPriceValue(po_amount);
    this.uuid = uuid();
    this.destroy = 0;
    this.purchaseOrderId = purchase_order_id;
    this.purchaseOrderNumber = purchase_order_number;
    this.budgetId = budget_id;
    this.budgetName = budget_name;
    this.categoryName = category_name;
    this.categoryId = category_id;

    this.trackingOptions = (tracking_options || []).map(ent => (
      new TrackingOptionModel(ent)
    ));
  }

  setPriceValue = (value) => {
    if (value) {
      return parseFloat(value / 100).toFixed(2);
    }
    return '';
  }

  isAssignedToBudget = () => this.budgetId;

  isAssigned = () => this.purchaseOrderId || this.budgetId;
}

export default VoucherTransactionModel;
