import React from 'react';
import SupplierItemModel from '../models/supplier_item_model';
import HeaderModel from '../models/header_model';
import TableHead from './item_list/table_head';
import SupplierItemRow from './item_list/supplier_item_row';
import ItemListService from '../services/item_list_service';
import BulkActionBar from './item_list/bulk_action_bar';
import FilterBar from './item_list/filter_bar';
import ImportItemsModal from './item_list/import_items_modal';
import Loader from './loader';
import Modal from './shared/modal';
import PaginateWithDots from './item_list/paginate_with_dots';
import MobileFiltersModal from './item_list/mobile_filters_modal';

class ItemsList extends React.Component {
  constructor(props) {
    super(props);

    this.urlParams = this.urlParamsSetup();
    const page = this.urlParams.page ? parseInt(this.urlParams.page) : 1;

    this.state = {
      supplierItems: [],
      showBulkActionBar: false,
      pages: 1,
      currentPage: page,
      allBulkActionSelected: false,
      selectedItemsCount: 0,
      sortHeader: null,
      statesFilterOptions: [],
      currentStateFilter: this.initStateFilter(this.urlParams.filters),
      suppliersFilterOptions: [],
      currentSupplierFilter: this.initCurrentSupplierFilter(this.urlParams.filters),
      categoriesFilterOptions: [],
      currentCategoryFilter: this.initCurrentCategoryFilter(this.urlParams.filters),
      groupsFilterOptions: [],
      currentGroupFilter: this.initCurrentGroupFilter(this.urlParams.filters),
      isImportModalOpen: false,
      isMobileFilterModalOpen: false,
      isLoading: false,
      suppliers: [],
      headers: [
        { name: 'supplier', translation: 'supplier_items.supplier' },
        { name: 'item_number', translation: 'supplier_items.item_number' },
        { name: 'owner_id', translation: 'supplier_items.owner_id' },
        { name: 'global_id', translation: 'supplier_items.global_id' },
        { name: 'description', translation: 'supplier_items.description' },
        { name: 'unit_measure', translation: 'supplier_items.unit_measure' },
        { name: 'moq', translation: 'supplier_items.moq', class: 'number' },
        { name: 'gross_price', translation: 'supplier_items.gross_price', class: 'number price' },
        { name: 'discount', translation: 'supplier_items.discount', class: 'number' },
        { name: 'net_price', translation: 'supplier_items.net_price', class: 'number price' },
        { name: 'currency', translation: 'supplier_items.currency' },
      ].map((header) => {
        if (this.urlParams.order.by === header.name) {
          const newHeader = new HeaderModel(header);
          newHeader.sortDirection = this.urlParams.order.type;
          return newHeader;
        }
        return new HeaderModel(header);
      }),
    };
  }

  componentDidMount = () => {
    const finalQuery = this.newUrlQueries();
    this.setState({ isLoading: true });
    ItemListService.index(finalQuery)
      .then(({
        supplier_items: supplierItem,
        possible_filters: {
          states, suppliers, categories, groups, pages,
        },
      }) => {
        this.setState({
          supplierItems: supplierItem.map(item => new SupplierItemModel(item)),
          statesFilterOptions: states,
          suppliersFilterOptions: suppliers,
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          pages,
          isLoading: false,
        });
      });
  }

  onChangeBulkAction = (supplierItemId) => {
    const { state: { supplierItems } } = this;
    const newSupplierItems = supplierItems.map((item) => {
      if (item.id === supplierItemId) {
        return item.changedBulkAction();
      }
      return item;
    });

    const anyUnselected = newSupplierItems.some(({ bulkActionsSelected }) => bulkActionsSelected === false);
    this.setState({ supplierItems: newSupplierItems, allBulkActionSelected: !anyUnselected }, () => this.resoulveBulkActionBar());
  }

  onSelectAllBulkAction = () => {
    const { state: { allBulkActionSelected, supplierItems } } = this;
    const newSupplierItems = supplierItems.map(item => item.selectAllBulkAction(allBulkActionSelected));

    this.setState({
      allBulkActionSelected: !allBulkActionSelected,
      supplierItems: newSupplierItems,
      showBulkActionBar: !allBulkActionSelected,
      selectedItemsCount: newSupplierItems.length,
    });
  }

  onSort = (sortHeader) => {
    const { state: { headers } } = this;
    const newHeaders = headers.map((header) => {
      if (header.name === sortHeader.name) {
        return header.setSortDirection();
      }
      return header.clean();
    });

    this.setState({
      headers: newHeaders,
    }, this.sortBy(sortHeader));
  }

  onToggleImportModal = () => {
    const { isImportModalOpen } = this.state;
    const newOpenState = !isImportModalOpen;
    this.setState({ isImportModalOpen: newOpenState });
    if (!newOpenState) this.updateItemsListAfterImport();
  }

  onToggleMobileFiltersModal =() => {
    this.setState(prevState => ({ isMobileFilterModalOpen: !prevState.isMobileFilterModalOpen }));
  }

  onSearchQuery = (value) => {
    this.urlParams.filters.query = [value];
    this.updateItemsList();
  }

  onPageChange = (page) => {
    if (page !== '...') {
      this.urlParams.page = page;
      this.setState({ currentPage: page }, () => this.updateItemsList(true));
    }
  }

  setStateFilter = (state) => {
    const { state: { currentStateFilter } } = this;
    if (currentStateFilter.id === state.id) {
      this.setState({ currentStateFilter: {} }, () => {
        this.updateItemsList();
      });
    } else {
      this.setState({ currentStateFilter: state }, () => {
        this.updateItemsList();
      });
    }
  }

  setCategoryFilter = (category) => {
    const { state: { currentCategoryFilter } } = this;
    if (currentCategoryFilter.id === category.id) {
      this.setState({ currentCategoryFilter: {} }, () => {
        this.updateItemsList();
      });
    } else {
      this.setState({ currentCategoryFilter: category }, () => {
        this.updateItemsList();
      });
    }
  }

  setGroupFilter = (group) => {
    const { state: { currentGroupFilter } } = this;
    if (currentGroupFilter.id === group.id) {
      this.setState({ currentGroupFilter: {} }, () => {
        this.updateItemsList();
      });
    } else {
      this.setState({ currentGroupFilter: group }, () => {
        this.updateItemsList();
      });
    }
  }

  setSupplierFilter = (supplier) => {
    const { state: { currentSupplierFilter } } = this;
    if (currentSupplierFilter.id === supplier.id) {
      this.setState({ currentSupplierFilter: {} }, () => {
        this.updateItemsList();
      });
    } else {
      this.setState({ currentSupplierFilter: supplier }, () => {
        this.updateItemsList();
      });
    }
  }

  setUrlFilterParams = (updatePageNumber) => {
    const {
      state: {
        currentSupplierFilter, currentStateFilter, currentCategoryFilter,
        currentGroupFilter, currentPage,
      },
    } = this;
    delete this.urlParams.filters.supplier_id;
    delete this.urlParams.filters.state;
    delete this.urlParams.filters.supplier_item_category_id;
    delete this.urlParams.filters.supplier_item_group_id;
    delete this.urlParams.page;

    if (currentSupplierFilter.id) {
      this.urlParams.filters.supplier_id = [currentSupplierFilter.id];
    }
    if (currentStateFilter.name) {
      this.urlParams.filters.state = [currentStateFilter.name];
    }
    if (currentCategoryFilter.id) {
      this.urlParams.filters.supplier_item_category_id = [currentCategoryFilter.id];
    }
    if (currentGroupFilter.id) {
      this.urlParams.filters.supplier_item_group_id = [currentGroupFilter.id];
    }
    if (updatePageNumber) {
      this.urlParams.page = currentPage;
    }
  }

  updateItemsList = (updatePageNumber = false) => {
    if (updatePageNumber) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoading: true, currentPage: 1 });
    }
    this.setUrlFilterParams(updatePageNumber);

    const finalQuery = this.newUrlQueries();

    window.history.pushState({}, 'SupplierItems', `/supplier_items/${finalQuery}`);
    ItemListService.index(finalQuery)
      .then(({ supplier_items: items, possible_filters: { pages } }) => {
        this.setState({
          supplierItems: items.map(item => new SupplierItemModel(item)),
          pages,
          isLoading: false,
        });
      });
  }

  updateItemsListAfterImport = () => {
    const finalQuery = this.newUrlQueries();
    this.setState({ isLoading: true });
    ItemListService.index(finalQuery)
      .then(({
        supplier_items: supplierItem,
        possible_filters: {
          states, suppliers, categories, groups, pages,
        },
      }) => {
        this.setState({
          supplierItems: supplierItem.map(item => new SupplierItemModel(item)),
          statesFilterOptions: states,
          suppliersFilterOptions: suppliers,
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          pages,
          isLoading: false,
        });
      });
  }

  newUrlQueries = () => {
    const { urlParams: { filters, order, page } } = this;

    const mappedFilters = [];
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'state' || key === 'query') {
        mappedFilters.push(`${key}=${value}`);
      } else {
        value.forEach(val => mappedFilters.push(`${key}[]=${val}`));
      }
    }

    const queries = [
      mappedFilters.join('&'),
      order.by ? `sort[by]=${order.by}&sort[type]=${order.type}` : '',
      page ? `page=${page}` : '',
    ];

    return `?${queries.join('&')}`;
  }

  afterBulkAction = () => {
    const { state: { showBulkActionBar } } = this;
    this.setState({
      showBulkActionBar: !showBulkActionBar,
      allBulkActionSelected: false,
    });
  }

  urlParamsSetup = () => {
    const params = URI(window.location.href).query(true);

    const filters = {};

    Object.keys(params).map((key) => {
      const value = Array.isArray(params[key]) ? params[key] : [params[key]];

      return filters[key.replace('[', '').replace(']', '')] = value;
    });

    const order = filters.sortby ? { by: filters.sortby.join(''), type: filters.sorttype.join('') } : {};
    const { page } = filters;

    delete filters.page;
    delete filters.sortby;
    delete filters.sorttype;
    delete filters.page;

    return { filters, order, page };
  }

  destroyAll = () => {
    const { state: { supplierItems } } = this;
    this.setUrlFilterParams();

    const finalQuery = this.newUrlQueries();

    const itemsIds = supplierItems.filter(item => item.bulkActionsSelected).map(item => item.id);

    ItemListService.destroyAll(itemsIds, finalQuery)
      .then((items) => {
        this.setState({
          supplierItems: items.map(item => new SupplierItemModel(item)),
        }, () => this.afterBulkAction());
      });
  }

  initCurrentSupplierFilter = (filters) => {
    if (filters.supplier_id) {
      const supplier_id = parseInt(filters.supplier_id[0]);
      return { id: supplier_id, value: supplier_id, filter_param: 'supplier_id' };
    }
    return {};
  }

  initCurrentCategoryFilter = (filters) => {
    if (filters.supplier_item_category_id) {
      const category_id = parseInt(filters.supplier_item_category_id[0]);
      return { id: category_id, value: category_id, filter_param: 'supplier_item_category_id' };
    }
    return {};
  }

  initCurrentGroupFilter = (filters) => {
    if (filters.supplier_item_group_id) {
      const group_id = parseInt(filters.supplier_item_group_id[0]);
      return { id: group_id, value: group_id, filter_param: 'supplier_item_group_id' };
    }
    return {};
  }

  initStateFilter = (filters) => {
    if (filters.state) {
      const state = filters.state[0];
      return {
        id: state, name: state, value: state, filter_param: 'state',
      };
    }
    return {};
  }

  sortBy = (sortByHeader) => {
    const { order } = this.urlParams;
    order.by = sortByHeader.name;
    order.type = sortByHeader.sortDirection;

    this.urlParams = { ...this.urlParams, order };
    this.updateItemsList();
  }

  restoreAll = () => {
    const { state: { supplierItems } } = this;

    const itemsIds = supplierItems.filter(item => item.bulkActionsSelected).map(item => item.id);

    ItemListService.restoreAll(itemsIds)
      .then((items) => {
        this.setState({
          supplierItems: items.map(item => new SupplierItemModel(item)),
        }, () => this.afterBulkAction());
      });
  }

  resoulveBulkActionBar = () => {
    const { state: { supplierItems } } = this;
    let count = 0;
    supplierItems.forEach((item) => { if (item.bulkActionsSelected) count += 1; });
    if (count !== 0) {
      this.setState({ showBulkActionBar: true, selectedItemsCount: count });
    } else {
      this.setState({ showBulkActionBar: false, selectedItemsCount: count });
    }
  }

  _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  )

  _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  )

  _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  )

  _renderColls = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="col" key={`${prefix}col_column_${i}`} />
    ))
  )

  render = () => {
    const {
      state: {
        supplierItems, showBulkActionBar, selectedItemsCount, allBulkActionSelected,
        headers, statesFilterOptions, currentStateFilter, suppliersFilterOptions,
        currentSupplierFilter, categoriesFilterOptions, groupsFilterOptions,
        currentCategoryFilter, currentGroupFilter, isImportModalOpen, isLoading,
        pages, currentPage, isMobileFilterModalOpen,
      },
    } = this;
    const dotsNumber = headers.length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div className="cell-16">
        <div className="toast_box" />
        <div className="tile with-table with-bulk" id="supplier_items_listing">
          <div className="has-filter has-popup mobile-filter">
            <input id="mobile-filters" type="checkbox" />
            <div className="popup">
              <div className="popup-content">
                <h3>{I18n.t('supplier_items.filter_by')}</h3>
                <div className="grid space-2 grid-form">
                  <div className="row" />
                </div>
              </div>
            </div>
          </div>
          <div className="tile-header">
            <Modal>
              <ImportItemsModal
                onToggleImportModal={this.onToggleImportModal}
                isImportModalOpen={isImportModalOpen}
                updateItemsList={this.updateItemsList}
              />
            </Modal>
            <h1>{I18n.t('supplier_items.item_list')}</h1>
            <div className="actions">
              <div className="items items-divided">
                <a href="/supplier_items/new" className="link">{I18n.t('supplier_items.add_new_item')}</a>
                <a onClick={this.onToggleImportModal} className="link">
                  {I18n.t('supplier_items.upload_items')}
                </a>
              </div>
            </div>
          </div>
          <div className="tile-content">
            {
              !showBulkActionBar
                ? (
                  <FilterBar
                    statesFilterOptions={statesFilterOptions}
                    currentStateFilter={currentStateFilter}
                    setStateFilter={this.setStateFilter}
                    suppliersFilterOptions={suppliersFilterOptions}
                    setCategoryFilter={this.setCategoryFilter}
                    categoriesFilterOptions={categoriesFilterOptions}
                    currentCategoryFilter={currentCategoryFilter}
                    setGroupFilter={this.setGroupFilter}
                    groupsFilterOptions={groupsFilterOptions}
                    currentGroupFilter={currentGroupFilter}
                    currentSupplierFilter={currentSupplierFilter}
                    setSupplierFilter={this.setSupplierFilter}
                    onSearchQuery={this.onSearchQuery}
                  />
                ) : null
            }
            <div className="has-bulk">
              {
                showBulkActionBar
                  ? (
                    <BulkActionBar
                      selectedItemsCount={selectedItemsCount}
                      destroyAll={this.destroyAll}
                      restoreAll={this.restoreAll}
                      state={currentStateFilter}
                      onSelectAllBulkAction={this.onSelectAllBulkAction}
                    />
                  ) : null
              }
              <div className={`table-fluid table-scrollable table-column-${dotsNumber} ${htmlClasses}`}>
                <Loader />
                {this._renderInputs(dotsNumber)}
                <div className="table-header with-filters">
                  <div className="mobile-filters">
                    <Modal>
                      <MobileFiltersModal
                        statesFilterOptions={statesFilterOptions}
                        currentStateFilter={currentStateFilter}
                        setStateFilter={this.setStateFilter}
                        suppliersFilterOptions={suppliersFilterOptions}
                        setCategoryFilter={this.setCategoryFilter}
                        categoriesFilterOptions={categoriesFilterOptions}
                        currentCategoryFilter={currentCategoryFilter}
                        setGroupFilter={this.setGroupFilter}
                        groupsFilterOptions={groupsFilterOptions}
                        currentGroupFilter={currentGroupFilter}
                        currentSupplierFilter={currentSupplierFilter}
                        setSupplierFilter={this.setSupplierFilter}
                        isMobileFilterModalOpen={isMobileFilterModalOpen}
                        onToggleMobileFiltersModal={this.onToggleMobileFiltersModal}
                      />
                    </Modal>
                    <label onClick={this.onToggleMobileFiltersModal}>
                      <i className="icon-filter_list" />
                    </label>
                  </div>
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber)}
                    {this._renderDots(dotsNumber)}
                  </div>
                </div>
                <div className="table table-sortable">
                  <div className="colgroup">
                    {this._renderColls(dotsNumber)}
                  </div>
                  <TableHead
                    headers={headers}
                    onSelectAllBulkAction={this.onSelectAllBulkAction}
                    allBulkActionSelected={allBulkActionSelected}
                    onSort={this.onSort}
                  />
                  <div className="tbody">
                    { supplierItems.map(supplierItem => (
                      <SupplierItemRow
                        key={supplierItem.id}
                        supplierItem={supplierItem}
                        onChangeBulkAction={this.onChangeBulkAction}
                      />
                    )) }
                  </div>
                </div>
              </div>
            </div>
            <div className="tile-footer">
              <PaginateWithDots onPageChange={this.onPageChange} numberPages={pages} selected={currentPage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemsList;
