import React from 'react';
import CellDiv from '@/components/ui/table/cell_div';
import CellProgressBar from '@/components/ui/table/cell_progress_bar';
import { parseDate } from '@/tools';

export default function PurchaseOrderRow({ purchaseOrder, selectPurchaseOrder }) {
  const receivedPercentage = (parseFloat(purchaseOrder.totalReceivedValue) / parseFloat(purchaseOrder.totalAmountInPoCurrency)) * 100.0;
  const chartClass = purchaseOrder.state === 'completed' || purchaseOrder.state === 'received' ? 'recived' : 'goods';

  return (
    <div className="tr auto">
      <input id={`row_${purchaseOrder.id}`} name="attach-to-transaction" type="radio" readOnly onClick={() => { selectPurchaseOrder(purchaseOrder.id); }} />
      <CellDiv className="td column-fit">
        <label className="has-checkbox" htmlFor={`row_${purchaseOrder.id}`}>
          <span className="icon-add_circle checkbox-green" />
        </label>
        {purchaseOrder.poNumber}
      </CellDiv>
      <CellDiv className="column-fit">{parseDate(purchaseOrder.raisedDate, 'dd.MM.yyyy')}</CellDiv>
      <CellDiv className="column-fit" hasEllipsis link dataEllipsis={purchaseOrder.supplierName}>
        <a className="link text-ellipsis w-10">
          {purchaseOrder.supplierName}
        </a>
      </CellDiv>
      <CellDiv hasEllipsis dataEllipsis={purchaseOrder.budgetName}>
        <div className="text-ellipsis w-10">
          {purchaseOrder.budgetName}
        </div>
      </CellDiv>
      <CellDiv hasEllipsis dataEllipsis={purchaseOrder.description} className="column-fit">
        <div className="text-ellipsis w-10">
          {purchaseOrder.description}
        </div>
      </CellDiv>
      <CellDiv className="number price bold column-fit">{purchaseOrder.totalAmount}</CellDiv>
      <CellDiv className="number price bold column-fit">{purchaseOrder.notInvoiced}</CellDiv>
      {deviationTd(purchaseOrder.deviation, purchaseOrder.deviationPrice, purchaseOrder.invoiced)}
      <CellDiv className="number price bold column-fit">{purchaseOrder.committed}</CellDiv>
      <CellDiv className="center">{purchaseOrder.invoicesCount}</CellDiv>
      <CellDiv className="center actions text-center">
        <a className="icon-attach_file link" href={`/purchase_orders/${purchaseOrder.id}.pdf`} target="_blank" />
      </CellDiv>
      <CellDiv className="has-charts short">
        <CellProgressBar chartClass={chartClass} valuePercentage={receivedPercentage} tooltip="received" />
      </CellDiv>
      <CellDiv className="center actions">
        <span className={`${purchaseOrder.state} status`}>{I18n.t(`purchase_orders.form.activities_keys.${purchaseOrder.state}`)}</span>
      </CellDiv>
      <CellDiv>
        <a className="avatar approve has-tooltip" href="#">
          <img src={purchaseOrder.user.avatar_url} />
          <span className="tooltip center">{purchaseOrder.user.full_name}</span>
        </a>
      </CellDiv>
    </div>
  );
}

function deviationTd(deviation, deviationPrice, invoiced) {
  if (deviation > 0) {
    return (
      <div className="td number price bold column-fit" data-beyond={deviationPrice}>{invoiced}</div>
    );
  }
  return (
    <div className="td number price bold column-fit">{invoiced}</div>
  );
}
