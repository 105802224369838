import React from 'react';
import PropTypes from 'prop-types';
import * as api from '../../shared/api';
import * as toast from '../../shared/toast';

class SupplierMessageModal extends React.Component {
  static propTypes = {
    supplierMessage: PropTypes.object,
    poSetup: PropTypes.object,
    isSentToSupplierOpen: PropTypes.bool,
    isSupplierMessageOpen: PropTypes.bool,
    submit: PropTypes.func,
    sendToSupplier: PropTypes.func,
    handleClose: PropTypes.func,
    purchaseOrder: PropTypes.object,
    updateSupplierMessage: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.isOpen = this.isOpen.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.send = this.send.bind(this);
    this.save = this.save.bind(this);
    this.makeSaveRequest = this.makeSaveRequest.bind(this);
    this.renderDescription = this.renderDescription.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.prefillEmail = this.prefillEmail.bind(this);
    this.updateEmail = this.updateEmail.bind(this);

    this.state = {
      id: props.supplierMessage.id,
      emails: this.prefillEmail(),
      cc: (props.supplierMessage.cc ? props.supplierMessage.cc.join(', ') : ''),
      subject: props.supplierMessage.subject || props.poSetup.supplier_message_subject || '',
      body: props.supplierMessage.body || props.poSetup.supplier_message_body || '',
      errors: {},
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    const { purchaseOrder: { supplier_contact_id, supplier_email } } = this.props;
    const contactEmailChanged = prevProps.purchaseOrder.supplier_contact_id !== supplier_contact_id;
    const supplierEmailChanged = prevProps.purchaseOrder.supplier_email !== supplier_email;

    if (contactEmailChanged || supplierEmailChanged) {
      this.updateEmail();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getData() {
    const {
      emails, cc, subject, body,
    } = this.state;
    return {
      emails: emails.replace(/ /g, '').split(','),
      cc: cc.replace(/ /g, '').split(','),
      subject,
      body,
    };
  }

  updateEmail() {
    this.setState({ emails: this.prefillEmail() });
  }

  prefillEmail() {
    const { supplierMessage, purchaseOrder: { supplier_contact_email, supplier_contact_id, supplier_email } } = this.props;
    const contact_email = supplier_contact_id ? supplier_contact_email : false;
    return supplierMessage.emails || contact_email || supplier_email || '';
  }

  makeSaveRequest() {
    const { purchaseOrder: { id }, updateSupplierMessage } = this.props;

    return api.makePostRequest(`/purchase_orders/${id}/supplier_message`, { supplier_message: this.getData() })
      .then((response) => {
        updateSupplierMessage(response.data.supplier_message);
        this.setState({ errors: {} });
        toast.successToast(I18n.t('supplier_messages.messages.saved'));
        return true;
      }).catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              this.setState({ errors: error.response.data });
              break;
            case 401:
              toast.errorToast(I18n.t('commons.messages.unauthorized'));
              break;
            case 500:
              toast.errorToast(I18n.t('commons.messages.error'));
              break;
            default:
              toast.errorToast(I18n.t('commons.messages.error'));
              Raven.captureException(error);
          }
        } else {
          toast.errorToast(I18n.t('commons.messages.error'));
          Raven.captureException(error);
        }
        return false;
      });
  }

  save() {
    this.makeSaveRequest();
  }

  send() {
    const { sendToSupplier } = this.props;
    this.makeSaveRequest().then((response) => {
      if (response) sendToSupplier();
    });
  }

  escFunction(event) {
    const { handleClose } = this.props;
    if (event.keyCode === 27) {
      handleClose();
    }
  }

  isOpen() {
    const { isSentToSupplierOpen, isSupplierMessageOpen } = this.props;
    return isSentToSupplierOpen || isSupplierMessageOpen || false;
  }

  submit(e) {
    const { isSentToSupplierOpen } = this.props;
    e.preventDefault();
    if (isSentToSupplierOpen) {
      this.send();
    } else {
      this.save();
    }
  }

  renderDescription() {
    const { isSentToSupplierOpen } = this.props;
    if (isSentToSupplierOpen) {
      return 'This email message will be send to supplier with PO.';
    }
    return 'This message will be sent to supplier after approve.';
  }

  renderButton() {
    const { isSentToSupplierOpen } = this.props;
    if (isSentToSupplierOpen) {
      return (<button className="button button-primary">Send to supplier</button>);
    }
    return (<button className="button button-primary">Save</button>);
  }

  render() {
    const {
      state: {
        errors, emails, cc, subject, body,
      },
      props: {
        handleClose,
      },
    } = this;

    return (
      [<input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={this.isOpen()} readOnly />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.submit}>
            <div className="modal-window window-inside window" id="sent-to-supplier-modal">
              <div className="window-header modal-header">
                {I18n.t('purchase_orders.form.sending_to_supplier')}
              </div>
              <div className="window-content">
                <div className="grid space-1 gutter-1">
                  <div className="row">
                    <div className="cell-16">
                      <p>{this.renderDescription()}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.emails ? 'has-error' : ''}`}>
                      <div className="as-input required has-tooltip">
                        <span className="label">{I18n.t('purchase_orders.form.to_supplier_email_address')}</span>
                        <input placeholder={I18n.t('purchase_orders.form.enter_email')} id="supplier_message_email" name="emails" type="text" value={emails} onChange={this.onChange} />
                        { errors.emails
                          ? <div className="hint">{errors.emails.join(', ')}</div> : ''
                        }
                        <div className="tooltip">
                          {I18n.t('purchase_orders.form.multiple_email_addresses')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.cc ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.cc')}</span>
                        <input placeholder={I18n.t('purchase_orders.form.enter_email')} id="supplier_message_cc" name="cc" type="text" value={cc} onChange={this.onChange} />
                        { errors.cc
                          ? <div className="hint">{errors.cc.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.subject ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.subject')}</span>
                        <input placeholder={I18n.t('purchase_orders.form.enter_subject')} id="supplier_message_subject" name="subject" type="text" value={subject} onChange={this.onChange} />
                        { errors.subject
                          ? <div className="hint">{errors.subject.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.body ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.message')}</span>
                        <textarea placeholder={I18n.t('purchase_orders.form.enter_comment')} id="supplier_message_body" name="body" rows={4} value={body} onChange={this.onChange} />
                        { errors.body
                          ? <div className="hint">{errors.body.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={handleClose}>{I18n.t('purchase_orders.form.cancel')}</label>
                  {this.renderButton()}
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={handleClose} />
        </div>]
    );
  }
}
export default SupplierMessageModal;
