import React from 'react';
import DropdownField from '../common/dropdown_field';

function PoNumberFieldWrapper(props) {
  const { prefilVoucherFields } = props;
  const updateVoucherPO = (fieldName, lookedEntity) => {
    prefilVoucherFields(fieldName, lookedEntity);
  };

  return (
    <DropdownField {...props} updateVoucher={updateVoucherPO} />
  );
}

export default PoNumberFieldWrapper;
