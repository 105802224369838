import React from 'react';
import { humanizedPrice } from '../shared/number_converter';

const SupplierItemRow = ({
  supplierItem, onChangeBulkAction,
}) => {
  const onRedirect = () => {
    const supplierItemUrl = `/supplier_items/${supplierItem.id}`;
    window.location.href = supplierItemUrl;
  };

  const netPrice = ({ grossPrice, discount }) => ((grossPrice * (100 - discount)) / 100);
  return (
    <div className="tr" style={{ cursor: 'pointer' }}>
      <div className="td has-child">
        <label className="has-checkbox as-td">
          <input
            className="count-selected"
            type="checkbox"
            checked={supplierItem.bulkActionsSelected}
            onChange={() => onChangeBulkAction(supplierItem.id)}
          />
          <span className="checkbox input-s" />
        </label>
        <div className="as-td has-ellipsis" data-ellipsis={supplierItem.supplier.name}>
          <div className="text-ellipsis w-7">
            <span onClick={onRedirect}>
              {supplierItem.supplier.name}
            </span>
          </div>
        </div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={supplierItem.number}>
        <div className="text-ellipsis w-7">{supplierItem.number}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={supplierItem.ownerNumber}>
        <div className="text-ellipsis w-7">{supplierItem.ownerNumber}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={supplierItem.globalNumber}>
        <div className="text-ellipsis w-5">{supplierItem.globalNumber}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={supplierItem.description}>
        <div className="text-ellipsis w-10">{supplierItem.description}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={supplierItem.unitMeasure}>
        <div className="text-ellipsis w-3">{supplierItem.unitMeasure}</div>
      </div>
      <div onClick={onRedirect} className="td number">{supplierItem.minimumOrderQuantity}</div>
      <div onClick={onRedirect} className="td number bold">
        {humanizedPrice(supplierItem.grossPrice)}
      </div>
      <div onClick={onRedirect} className="td number">
        {`${humanizedPrice(supplierItem.discount)} %`}
      </div>
      <div onClick={onRedirect} className="td number bold">
        {humanizedPrice(netPrice(supplierItem))}
      </div>
      <div onClick={onRedirect} className="td">{supplierItem.currency}</div>
      <div className="td actions sticky-right hoverable">
        <a href={`/supplier_items/${supplierItem.id}/edit`} className="edit">
          <i className="icon-edit" />
        </a>
        <a
          href={`/supplier_items/${supplierItem.id}`}
          className="delete"
          rel="noflow"
          data-method="delete"
        >
          <i className="icon-delete" />
        </a>
      </div>
    </div>
  );
};

export default SupplierItemRow;
