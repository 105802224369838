import React from 'react';

const TableHead = ({
  headers, onSelectAllBulkAction, allBulkActionSelected, onSort,
}) => (
  <thead>
    <tr>
      <th>
        <label className="has-checkbox">
          <input
            className="count-selected"
            type="checkbox"
            checked={allBulkActionSelected}
            onChange={() => onSelectAllBulkAction()}
          />
          <span className="checkbox input-s" />
        </label>
      </th>
      { headers.map(header => (
        <th
          key={header.name}
          className={`th ${header.class}`}
          data-name={header.name}
          onClick={() => { if (header.name !== 'supplier') onSort(header); }}
        >
          {I18n.t(header.translation)}
          {
            header.sortDirection !== ''
              ? (
                <a className={`sort ${header.sortDirection}`} />
              ) : null
          }
        </th>
      )) }
    </tr>
  </thead>
);

export default TableHead;
