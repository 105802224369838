import React from 'react';

function ApproverButtons({ reject, onHold, approve }) {
  return (
    <React.Fragment>
      <a className="button button-warning" onClick={reject}>
        {I18n.t('purchase_orders.form.reject')}
      </a>
      <a className="button button-mute" onClick={onHold}>
        {I18n.t('vouchers.edit.on_hold')}
      </a>
      <a className="button button-primary" onClick={approve}>
        {I18n.t('purchase_orders.form.approve')}
      </a>
    </React.Fragment>
  );
}

export default ApproverButtons;
