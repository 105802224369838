import React from 'react';
import Button from '../../common/button';

function DefaultButtons({
  saveVoucher, saveAndCloseVoucher, deleteVoucher, isSavePrimaryButton,
  deleted, restoreVoucher,
}) {
  return (
    <React.Fragment>
      {
        deleted ? (
          <a
            className="button inverse button-warning"
            onClick={restoreVoucher}
          >
            {I18n.t('commons.actions.restore')}
          </a>

        ) : (
          <a
            className="button inverse button-warning"
            onClick={deleteVoucher}
          >
            {I18n.t('commons.actions.destroy')}
          </a>
        )
      }
      <a
        className="button inverse button-mute"
        onClick={saveAndCloseVoucher}
      >
        {I18n.t('commons.actions.save_and_close')}
      </a>
      <Button onClick={() => saveVoucher()} primary={isSavePrimaryButton}>
        {I18n.t('commons.actions.save')}
      </Button>
    </React.Fragment>
  );
}

export default DefaultButtons;
