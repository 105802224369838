import {
  makePostFileRequest, makeGetRequest, makePutRequest, makeDeleteRequest,
  makePostRequest,
} from '../components/shared/api';

class VoucherService {
  static create = ({ fileToImport, purchaseOrderId, projectId }) => {
    const formData = new FormData();

    if (fileToImport) formData.append('voucher[attachments][]', fileToImport);
    if (purchaseOrderId) formData.append('voucher[purchase_order_id]', purchaseOrderId);
    if (projectId) formData.append('voucher[project_id]', projectId);

    return makePostFileRequest('/api/accountings/vouchers', formData)
      .then(response => response.data);
  }

  static active = voucherId => makeGetRequest(`/vouchers/${voucherId}/active`)
    .then((response) => {
      const { data } = response;

      return ({
        jobProcessing: data.job_processing,
        id: data.id,
      });
    })

  static terminateOCRProcess = voucherId => makeGetRequest(`/vouchers/${voucherId}/terminate_ocr`)

  static edit = voucherId => makeGetRequest(`/vouchers/${voucherId}/edit`)
    .then(({ data }) => ({ possible_approvers: data.possible_approvers, ...data.voucher, errors: data.errors }))

  static update = (voucherId, params) => makePutRequest(`/api/accountings/vouchers/${voucherId}`, params, false)
    .then(({ data }) => ({ possible_approvers: data.possible_approvers, ...data.voucher, errors: data.errors }))

  static createComment = (voucher, comment) => makePostRequest(`/api/accountings/vouchers/${voucher.id}/voucher_comments`, comment.toParams())
    .then(reposne => reposne.data)

  static updateWithEvent = (voucher, params) => makePutRequest(`/api/accountings/vouchers/${voucher.id}`, params)
    .then(response => ({ possible_approvers: response.data.possible_approvers, ...response.data.voucher }))

  static destroy = voucher => makeDeleteRequest(
    `/api/accountings/vouchers/${voucher.id}`,
  ).then(response => response.data)

  static postInvoice = voucher => makePostRequest(`/api/accountings/vouchers/${voucher.id}/post_invoice`, {}, false)
    .then(response => ({ possible_approvers: response.data.possible_approvers, ...response.data.voucher }))

  static print = id => makeGetRequest(`/api/v1/vouchers/${id}/print`)
    .then(response => response.data)

  static upload = (id, params) => makePutRequest(`/api/accountings/vouchers/${id}/upload`, params, false)
    .then(response => response.data)

  static updateOcrFields = (voucher, data) => makePutRequest(`/api/accountings/vouchers/${voucher.id}/update_ocr_fields`, data)
    .then(response => ({ possible_approvers: response.data.possible_approvers, ...response.data.voucher }))
}

export default VoucherService;
