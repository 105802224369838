import React from 'react';
import ExportButton from '../shared/buttons/export_button';

class FilterBar extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      searchValue: '',
    };
  }

  exportUrl = (format, type) => {
    const url = new URL(`${window.location.origin}/api/supplier_items.${format}${window.location.search}`);
    if (type) { url.searchParams.append('type', type); }

    return url;
  }

  onSearch = (e) => {
    if (this.timer) clearTimeout(this.timer);

    this.setState({ searchValue: e.target.value.replace('%', '') }, () => {
      this.timer = setTimeout(() => {
        const { state: { searchValue }, props: { onSearchQuery } } = this;
        onSearchQuery(searchValue);
      }, 500);
    });
  }

  render() {
    const {
      props: {
        statesFilterOptions, currentStateFilter, setStateFilter,
        suppliersFilterOptions, currentSupplierFilter, setSupplierFilter,
        categoriesFilterOptions, currentCategoryFilter, setCategoryFilter,
        groupsFilterOptions, currentGroupFilter, setGroupFilter,
      },
      state: {
        searchValue,
      },
    } = this;
    return (
      <div className="table-actions">
        <div className="filters t-under-hide">
          <span className={`select input-xs filter-select can-unselect ${currentStateFilter.id ? 'selected' : ''}`}>
            <input type="checkbox" />
            <div className="select-content">
              {
                statesFilterOptions.map(state => (
                  <label key={state.id} className={`${currentStateFilter.id === state.id ? 'checked' : ''}`} onClick={() => setStateFilter(state)}>{state.name}</label>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.states')} />
            </div>
          </span>
          <span className={`select input-xs filter-select can-unselect ${currentSupplierFilter.id ? 'selected' : ''}`}>
            <input type="checkbox" />
            <div className="select-content">
              {
                suppliersFilterOptions.map(supplier => (
                  <label key={supplier.id} className={`${currentSupplierFilter.id === supplier.id ? 'checked' : ''}`} onClick={() => setSupplierFilter(supplier)}>{supplier.name}</label>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.supplier')} />
            </div>
          </span>
          <span className={`select input-xs filter-select can-unselect ${currentCategoryFilter.id ? 'selected' : ''}`}>
            <input type="checkbox" />
            <div className="select-content">
              {
                categoriesFilterOptions.map(cateogry => (
                  <label key={cateogry.id} className={`${currentCategoryFilter.id === cateogry.id ? 'checked' : ''}`} onClick={() => setCategoryFilter(cateogry)}>{cateogry.name}</label>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.categories')} />
            </div>
          </span>
          <span className={`select input-xs filter-select can-unselect ${currentGroupFilter.id ? 'selected' : ''}`}>
            <input type="checkbox" />
            <div className="select-content">
              {
                groupsFilterOptions.map(group => (
                  <label key={group.id} className={`${currentGroupFilter.id === group.id ? 'checked' : ''}`} onClick={() => setGroupFilter(group)}>{group.name}</label>
                ))
              }
              <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.groups')} />
            </div>
          </span>
        </div>
        <div className="right-actions">
          <div className="items items-divided">
            <div className="control icon-search input-search left-icon">
              <input
                className="input-s width-2"
                id="items_search"
                placeholder={I18n.t('search')}
                type="text"
                value={searchValue}
                onChange={this.onSearch}
              />
            </div>
            <ExportButton exportUrl={this.exportUrl} />
          </div>
        </div>
      </div>
    );
  }
}

export default FilterBar;
