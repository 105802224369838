import * as moment from 'moment';
import uuid from 'uuid/v4';
import convertToCents from '../components/shared/convert_to_cents';
import VoucherAttachmentModel from './voucher_attachment_model';
import VoucherTransactionModel from './voucher_transaction_model';
import VoucherCommentModel from './voucher_comment_model';
import PurchaseOrderNumberModel from './dropdowns_models/purchase_order_number_model';
import ProjectModel from './dropdowns_models/project_model';
import SupplierModel from './dropdowns_models/supplier_model';
import AccountModel from './dropdowns_models/account_model';
import TypeModel from './dropdowns_models/type_model';
import UserModel from './user_model';
import VoucherFileModel from './VoucherFileModel';
import PredictonVoucherModel from './prediction_voucher_model';
import TrackingOptionModel from './tracking_option_model';
import CurrencyModel from './dropdowns_models/currency_model';

class VoucherModel {
  constructor({
    id, purchase_order_id, project_id, attachments, outdated,
    supplier, account, bank_account, cid, amount_cents, purchase_order,
    currency_amount_cents, doc_number, currency, transactions, editable,
    internal_number, doc_date, due_date, voucher_type, comments, created_at,
    state, deleted_at, permitted_events, possible_approvers, approved_by,
    updated_at, reference, processing, prediction_voucher, exchange_rate,
    prediction, transferred_at, duplicated, tracking_options, paid, files,
  }) {
    this.id = id;
    this.number = internal_number;
    this.poNumber = purchase_order ? new PurchaseOrderNumberModel(purchase_order) : new PurchaseOrderNumberModel({});
    this.project = new ProjectModel({ id: project_id });
    this.supplier = supplier ? new SupplierModel(supplier) : new SupplierModel({});
    this.account = new AccountModel(account);
    this.type = new TypeModel({ id: voucher_type, name: voucher_type });
    this.bankAccount = bank_account || '';
    this.reference = reference || '';
    this.cid = cid || '';
    this.outdated = outdated || false;
    this.amount = this.setPriceValue(amount_cents) || '';
    this.currencyAmount = this.setPriceValue(currency_amount_cents) || '';
    this.invoiceNumber = doc_number || '';
    this.currency = new CurrencyModel(currency);
    this.createdAt = created_at;
    this.transferredAt = transferred_at;
    this.uuid = uuid();
    this.state = state;
    this.deletedAt = deleted_at;
    this.possibleApprovers = possible_approvers ? possible_approvers.map(user => new UserModel(user)) : [];
    this.permittedEvents = permitted_events;
    this.approvedBy = approved_by;
    this.editable = editable;
    this.updatedAt = updated_at;
    this.processing = processing;
    this.duplicated = duplicated;
    this.paid = paid || false;
    this.purchaseOrderId = purchase_order_id;
    this.exchangeRate = exchange_rate || 1;
    this.outdated = outdated || false;

    this.trackingOptions = (tracking_options || []).map(ent => (
      new TrackingOptionModel(ent)
    ));
    this.transactions = (transactions || []).map(transaction => (
      new VoucherTransactionModel(transaction)
    ));
    this.comments = (comments || []).map(comment => (
      new VoucherCommentModel(comment)
    ));
    this.files = (files || []).map(file => (
      new VoucherFileModel(file)
    ));

    this.prediction = prediction;
    this.predictionVoucher = (prediction_voucher && prediction_voucher.id) ? new PredictonVoucherModel(prediction_voucher) : null;

    doc_date
      ? this.docDate = moment.utc(doc_date)
      : null;
    due_date
      ? this.dueDate = moment.utc(due_date)
      : null;

    attachments
      ? this.attachments = attachments.map((attachment, index) => {
        if (index === 0) {
          return new VoucherAttachmentModel({ ...attachment, selected: true });
        }
        return new VoucherAttachmentModel(attachment);
      })
      : [];
  }

  predictionFor = (field) => {
    if (!this.predictionVoucher) return null;

    if (this.predictionVoucher[field]) {
      const confidence = this.predictionVoucher[`${field}Confidence`];
      return (`${(confidence * 100).toFixed(2)}%`);
    }
    return '0.00%';
  }

  predictionValueFor = (field) => {
    if (this.predictionVoucher && this.predictionVoucher[field]) {
      const confidence = this.predictionVoucher[`${field}Confidence`];
      return (confidence * 100).toFixed(2);
    }

    return null;
  }

  setPriceValue = (value) => {
    if (value) {
      return parseFloat(value / 100).toFixed(2);
    }
    return '';
  }

  formattedDocDate = () => {
    if (this.docDate) {
      return this.docDate.format('DD.MM.YYYY');
    }
    return '';
  }

  formattedDueDate = () => {
    if (this.dueDate) {
      return this.dueDate.format('DD.MM.YYYY');
    }
    return '';
  }

  humanizedState = () => {
    if (this.state === 'complete') return 'validated';
    if (this.state === 'sent_to_approve') return 'sent for approval';
    return this.state.replace(/_/g, ' ');
  }

  toFormData = (voucher) => {
    const formData = new FormData();

    voucher.attachments.map((attachment, index) => {
      formData.append('voucher[attachments][]', attachment.toFile(), `${index}_pdf_page.png`);
    });

    formData.append('voucher[reference]', voucher.reference);
    formData.append('voucher[paid]', voucher.paid);

    if (voucher.purchaseOrderId) formData.append('voucher[purchase_order_id]', voucher.poNumber?.poNumber ? voucher.purchaseOrderId : '');
    if (voucher.poNumber) formData.append('voucher[po_number]', voucher.poNumber.poNumber || '');
    if (voucher.type) formData.append('voucher[voucher_type]', voucher.type.id);
    if (voucher.invoiceNumber) formData.append('voucher[doc_number]', voucher.invoiceNumber);
    if (voucher.supplier.id) formData.append('voucher[supplier_id]', voucher.supplier.id);
    if (voucher.amount) formData.append('voucher[amount_cents]', Math.round(voucher.amount * 100));
    if (voucher.currency.id) formData.append('voucher[currency]', voucher.currency.id);
    if (voucher.currencyAmount) formData.append('voucher[currency_amount_cents]', Math.round(voucher.currencyAmount * 100));
    if (voucher.docDate) formData.append('voucher[doc_date]', voucher.docDate);
    if (voucher.dueDate) formData.append('voucher[due_date]', voucher.dueDate);
    if (voucher.bankAccount) formData.append('voucher[bank_account]', voucher.bankAccount);
    if (voucher.cid) formData.append('voucher[cid]', voucher.cid);
    if (voucher.account.id) formData.append('voucher[account_id]', voucher.account.id);
    if (voucher.project.id) formData.append('voucher[project_id]', voucher.project.id);
    if (voucher.event) formData.append('voucher[event]', voucher.event);
    if (voucher.approverIds) {
      voucher.approverIds.map(approverId => formData.append('voucher[approver_ids][]', approverId));
    }
    voucher.trackingOptions.map((voucherTrackingOption, trackingOptionIndex) => {
      if (voucherTrackingOption.entityTrackingOptionId) formData.append(`voucher[voucher_tracking_options_attributes[${trackingOptionIndex}][id]]`, voucherTrackingOption.entityTrackingOptionId);
      formData.append(`voucher[voucher_tracking_options_attributes[${trackingOptionIndex}][tracking_option_id]]`, voucherTrackingOption.id);
      formData.append(`voucher[voucher_tracking_options_attributes[${trackingOptionIndex}][_destroy]]`, voucherTrackingOption.destroy);
    });

    voucher.transactions.map((transaction, index) => {
      if (transaction.id) formData.append(`voucher[transactions_attributes[${index}[id]]]`, transaction.id);
      if (transaction.account.id) formData.append(`voucher[transactions_attributes[${index}[account_id]]]`, transaction.account.id);
      if (transaction.vat.id) formData.append(`voucher[transactions_attributes[${index}[tax_code_id]]]`, transaction.vat.id);
      if (transaction.department.id) formData.append(`voucher[transactions_attributes[${index}[department_id]]]`, transaction.department.id);
      if (transaction.project.id) formData.append(`voucher[transactions_attributes[${index}[project_id]]]`, transaction.project.id);
      formData.append(`voucher[transactions_attributes[${index}[_destroy]]]`, transaction.destroy);
      formData.append(`voucher[transactions_attributes[${index}[included_vat]]]`, convertToCents(transaction.amount));
      formData.append(`voucher[transactions_attributes[${index}[description]]]`, transaction.description);
      formData.append(`voucher[transactions_attributes[${index}[value_cents]]]`, convertToCents(transaction.exVat));
      if (transaction.currencyValue) formData.append(`voucher[transactions_attributes[${index}[currency_value_cents]]]`, convertToCents(transaction.currencyValue));
      if (transaction.poAmount) formData.append(`voucher[transactions_attributes[${index}[po_amount]]]`, convertToCents(transaction.poAmount));
      transaction.trackingOptions.map((transactionTrackingOption, trackingOptionIndex) => {
        if (transactionTrackingOption.entityTrackingOptionId) formData.append(`voucher[transactions_attributes[${index}[transaction_tracking_options_attributes[${trackingOptionIndex}]][id]]]`, transactionTrackingOption.entityTrackingOptionId);
        formData.append(`voucher[transactions_attributes[${index}[transaction_tracking_options_attributes[${trackingOptionIndex}]][tracking_option_id]]]`, transactionTrackingOption.id);
        formData.append(`voucher[transactions_attributes[${index}[transaction_tracking_options_attributes[${trackingOptionIndex}]][_destroy]]]`, transactionTrackingOption.destroy);
      });
    });

    voucher.comments.map((comment, index) => {
      if (comment.id) formData.append(`voucher[comments_attributes[${index}[id]]]`, comment.id);
      formData.append(`voucher[comments_attributes[${index}[body]]]`, comment.body);
    });

    return formData;
  }
}

export default VoucherModel;
