import React from "react";
import { formatNumber, parseDate } from "@/tools";
import { PAYMENT_METHODS } from "@/config/constants";

export default function PreviewPaymentRow({ payment }) {
  const { paymentMethod, paymentDate, usageRate, value, purchaseOrderInvoiceId } = payment;

  const rowActions = () => {
    if (purchaseOrderInvoiceId)
      return (
        <div className="ct-text-gray ct-text-sm">
          <i className="icon-lock ct-text-base"></i>
        </div>
      );
  };

  function trBackground() {
    if (purchaseOrderInvoiceId) return "ct-bg-green-light";
    if (paymentMethod === PAYMENT_METHODS.RESIDUAL) return "ct-bg-opacity-50";

    return "ct-bg-white";
  }

  return (
    <tr className={`${trBackground()} ct-text-xs ct-h-6 ct-text-black-1`}>
      <td className="ct-capitalize custom">{paymentMethod}</td>
      <td className="ct-text-left custom">{parseDate(paymentDate, "dd.MM.yyyy")}</td>
      <td className="ct-font-bold ct-text-center custom">{usageRate.toFixed(2)}%</td>
      <td className="ct-text-right custom">{formatNumber(value)}</td>
      <td className="ct-w-0 custom">{rowActions()}</td>
    </tr>
  );
}
