import React, { useState } from 'react';
import { DateTime } from 'luxon';
import VoucherCommentModel from '../../../models/voucher_comment_model';

function CommentInput({
  voucher, updateVoucher, currentUser, saveVoucherComments,
}) {
  const [commentBody, setCommentBody] = useState('');

  const addCommentToVoucher = () => {
    if (commentBody.trim().length === 0) return;
    const userAttributes = {
      id: currentUser.id,
      first_name: currentUser.firstName,
      last_name: currentUser.lastName,
      avatar_url: currentUser.avatarUrl,
    };

    const newComment = new VoucherCommentModel({
      body: commentBody,
      user: userAttributes,
      action_type: 'commented',
      created_at: DateTime.local(),
    });
    const newVoucher = voucher;

    newVoucher.comments = [...voucher.comments, newComment];

    updateVoucher(newVoucher, saveVoucherComments(newComment));
    setCommentBody('');
  };

  const onChange = ({ target: { value } }) => {
    setCommentBody(value);
  };

  const handleSubmitPress = (e) => {
    if (e.key == 'Enter' && commentBody.trim().length > 1) {
      e.preventDefault();
      addCommentToVoucher();
    } else {
      false;
    }
  };

  return (
    <div className="new-comment">
      <textarea
        type="text"
        name="new_comment"
        className="auto-height"
        placeholder="Add comment"
        style={{ height: '233px', overflow: 'hidden' }}
        value={commentBody}
        onChange={onChange}
        onKeyPress={handleSubmitPress}
      />
      <button className="submit" onClick={addCommentToVoucher}>
        <i className="icon-send" />
      </button>
    </div>
  );
}

export default CommentInput;
