import React, { useEffect, useState } from 'react';
import { humanizedPrice } from '../../../shared/number_converter';

const SupplierItemRow = ({
  supplierItem, selectedItems, onChangeBulkAction,
}) => {
  const netPrice = ({ grossPrice, discount }) => ((grossPrice * (100 - discount)) / 100);
  const [elementSelected, setElementSelected] = useState(false);

  useEffect(() => {
    setElementSelected(selectedItems.find(({ id }) => id === supplierItem.id));
  }, [selectedItems]);

  return (
    <tr>
      <td>
        <label className="has-checkbox">
          <input
            className="count-selected"
            type="checkbox"
            checked={elementSelected}
            onChange={() => onChangeBulkAction(supplierItem.id)}
          />
          <span className="checkbox input-s" />
        </label>
      </td>
      <td>{supplierItem.number}</td>
      <td>{supplierItem.ownerNumber}</td>
      <td>{supplierItem.globalNumber}</td>
      <td>{supplierItem.description}</td>
      <td>{supplierItem.unitMeasure}</td>
      <td className="number">{supplierItem.minimumOrderQuantity}</td>
      <td className="number bold">
        {humanizedPrice(supplierItem.grossPrice)}
      </td>
      <td className="number">
        {`${humanizedPrice(supplierItem.discount)} %`}
      </td>
      <td className="number bold">
        {humanizedPrice(netPrice(supplierItem))}
      </td>
    </tr>
  );
};

export default SupplierItemRow;
