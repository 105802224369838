import * as Api from '../components/shared/api';

class ItemListService {
  static index = params => (
    Api.makeGetRequest(`/api/supplier_items${params}`).then(response => response.data)
  )

  static destroyAll = (supplierItemsIds, filterParams) => {
    const params = { supplier_items_ids: supplierItemsIds };
    return Api.makePutRequest(`/api/supplier_items/destroy_all${filterParams}`, params).then(response => response.data.supplier_items);
  }

  static restoreAll = (supplierItemsIds) => {
    const params = { supplier_items_ids: supplierItemsIds };
    return Api.makePutRequest('/api/supplier_items/restore_all?state=inactive', params).then(response => response.data.supplier_items);
  }
}

export default ItemListService;
