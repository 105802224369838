import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from '../../shared/modal/modal_header';
import ModalFooter from '../../shared/modal/modal_footer';
import ModalWrapper from '../../shared/modal/modal_wrapper';
import ModalContent from '../../shared/modal/modal_content';

class BulkErrorModal extends React.Component {
  static propTypes = {
    close: PropTypes.func,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    const { close } = this.props;
    if (event.keyCode === 27) {
      close();
    }
  }

  render() {
    const { close } = this.props;

    return (
      <ModalWrapper
        type="centered"
        active
        closeModal={close}
        windowType="window-inside"
      >
        <ModalHeader
          additionalClass="window-header-auto padding-bottom-0"
        >
          <span>
            <h1>{I18n.t('purchase_orders.invoices.bulk_action_error_header')}</h1>
          </span>
        </ModalHeader>
        <ModalContent>
          <p className="mute">
            {I18n.t('purchase_orders.invoices.bulk_action_error_line1')}
          </p>
          <p className="mute">
            {I18n.t('purchase_orders.invoices.bulk_action_error_line2')}
          </p>
        </ModalContent>
        <ModalFooter>
          <div className="items">
            <label className="button button-transparent button-mute inverse link" onClick={close}>{I18n.t('purchase_orders.form.cancel')}</label>
          </div>
        </ModalFooter>
      </ModalWrapper>
    );
  }
}
export default BulkErrorModal;
