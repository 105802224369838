import React from 'react';
import SupplierItemsModalService from '../../../services/supplier_items_modal_service';
import SupplierItemModel from '../../../models/supplier_item_model';
import HeaderModel from '../../../models/header_model';
import FilterBar from './supplier_items_modal/filter_bar';
import TableHead from './supplier_items_modal/table_head';
import SupplierItemRow from './supplier_items_modal/supplier_item_row';
import Loader from '../../loader';
import * as Swipe from '../../shared/swipe';

class SupplierItemsModal extends React.Component {
  constructor(props) {
    super(props);

    this.urlParams = {
      filters: { supplier_id: [props.purchaseOrderSupplierId] },
      order: {},
      page: 1,
    };

    this.ableToLoadMore = true;

    this.state = {
      supplierItems: [],
      categoriesFilterOptions: [],
      currentCategoryFilter: {},
      groupsFilterOptions: [],
      currentGroupFilter: {},
      allBulkActionSelected: false,
      selectedItemsCount: 0,
      selectedItems: [],
      isLoading: false,
      headers: [
        { name: 'item_number', translation: 'supplier_items.item_number', class: 'pointer' },
        { name: 'owner_id', translation: 'supplier_items.owner_id', class: 'pointer' },
        { name: 'global_id', translation: 'supplier_items.global_id', class: 'pointer' },
        { name: 'description', translation: 'supplier_items.description', class: 'pointer' },
        { name: 'unit_measure', translation: 'supplier_items.unit_measure', class: 'pointer' },
        { name: 'moq', translation: 'supplier_items.moq', class: 'number pointer' },
        { name: 'gross_price', translation: 'supplier_items.gross_price', class: 'number price pointer' },
        { name: 'discount', translation: 'supplier_items.discount', class: 'number pointer' },
        { name: 'net_price', translation: 'supplier_items.net_price', class: 'number price pointer' },
      ].map(header => new HeaderModel(header)),
    };
  }

  componentDidMount = () => {
    this.setUrlFilterParams();
    const finalQuery = this.newUrlQueries();

    this.setState({ isLoading: true });
    SupplierItemsModalService.index(finalQuery)
      .then(({
        supplier_items: supplierItems,
        possible_filters: {
          categories, groups, pages,
        },
      }) => {
        this.setState({
          supplierItems: supplierItems.map(item => new SupplierItemModel(item)),
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          pages,
          isLoading: false,
        });
        const scrollElement = document.querySelector('.window-content.supplier-items');
        scrollElement.addEventListener('scroll', this.onScroll, false);
        Swipe.triggerSwipeEvent();
      });
  }

  onSort = (sortHeader) => {
    const { state: { headers } } = this;
    const newHeaders = headers.map((header) => {
      if (header.name === sortHeader.name) {
        return header.setSortDirection();
      }
      return header.clean();
    });

    this.setState({
      headers: newHeaders,
    }, this.sortBy(sortHeader));
  }

  onChangeBulkAction = (supplierItemId) => {
    const { state: { supplierItems, selectedItems } } = this;
    const clickedItem = supplierItems.find(item => item.id === supplierItemId);
    const alreadySelectedItem = selectedItems.find(({ id }) => id === supplierItemId);
    const newSupplierItems = alreadySelectedItem ? selectedItems.filter(({ id }) => id !== supplierItemId) : [...selectedItems, clickedItem];
    this.setState({ selectedItems: newSupplierItems }, () => this.resoulveBulkActionBar());
  }

  onSelectAllBulkAction = () => {
    const { state: { allBulkActionSelected, supplierItems, selectedItems } } = this;
    const newSelectedItem = allBulkActionSelected ? [] : [...new Set([...selectedItems, ...supplierItems])];
    this.setState({
      selectedItems: newSelectedItem,
      selectedItemsCount: newSelectedItem.length,
      allBulkActionSelected: !allBulkActionSelected,
    });
  }

  onSearchQuery = (value) => {
    this.urlParams.filters.query = [value];
    this.updateItemsList();
  }

  onScroll = (e) => {
    if (!this.ableToLoadMore) return;

    const { scrollTop, scrollHeight } = e.target;

    if (scrollTop + 1000 >= scrollHeight) {
      this.ableToLoadMore = false;
      this.infiniteScrollPaginate();
    }
  }

  infiniteScrollPaginate = () => {
    this.setState({ isLoading: true });

    const { supplierItems } = this.state;
    const { page } = this.urlParams;
    this.urlParams.page = page + 1;

    const finalQuery = this.newUrlQueries();

    SupplierItemsModalService.index(finalQuery)
      .then(({ supplier_items: items }) => {
        this.setState({
          supplierItems: [...supplierItems, ...items.map(item => new SupplierItemModel(item))],
          isLoading: false,
        });
        const scrollElement = document.querySelector('.window-content.supplier-items');

        scrollElement.addEventListener('scroll', this.onScroll, false);
        this.ableToLoadMore = items.length > 0;
      });
  }

  sortBy = (sortByHeader) => {
    const order = {
      by: sortByHeader.name,
      type: sortByHeader.sortDirection,
    };

    this.urlParams = { ...this.urlParams, order };
    this.updateItemsList();
  }

  updateItemsList = () => {
    this.setState({ isLoading: true });
    this.setUrlFilterParams();

    const finalQuery = this.newUrlQueries();

    SupplierItemsModalService.index(finalQuery)
      .then(({ supplier_items: items }) => {
        this.setState({
          supplierItems: items.map(item => new SupplierItemModel(item)),
          isLoading: false,
        });
        const scrollElement = document.querySelector('.window-content.supplier-items');

        scrollElement.scrollTop = 0;
        scrollElement.addEventListener('scroll', this.onScroll, false);
        this.ableToLoadMore = items.length > 0;
      });
  }

  setUrlFilterParams = () => {
    const {
      state: {
        currentCategoryFilter, currentGroupFilter,
      },
    } = this;
    delete this.urlParams.filters.state;
    delete this.urlParams.filters.supplier_item_category_id;
    delete this.urlParams.filters.supplier_item_group_id;
    delete this.urlParams.page;

    if (currentCategoryFilter.id) {
      this.urlParams.filters.supplier_item_category_id = [currentCategoryFilter.id];
    }
    if (currentGroupFilter.id) {
      this.urlParams.filters.supplier_item_group_id = [currentGroupFilter.id];
    }
    this.urlParams.page = 1;
  }

  newUrlQueries = () => {
    const { urlParams: { filters, order, page } } = this;

    const mappedFilters = [];
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'state' || key === 'query') {
        mappedFilters.push(`${key}=${value}`);
      } else {
        value.forEach(val => mappedFilters.push(`${key}[]=${val}`));
      }
    }

    const queries = [
      mappedFilters.join('&'),
      order.by ? `sort[by]=${order.by}&sort[type]=${order.type}` : '',
      page ? `page=${page}` : '',
    ];

    return `?${queries.join('&')}`;
  }

  setCategoryFilter = (category) => {
    const { state: { currentCategoryFilter } } = this;
    if (currentCategoryFilter.id === category.id) {
      this.setState({ currentCategoryFilter: {} }, () => {
        this.updateItemsList();
      });
    } else {
      this.setState({ currentCategoryFilter: category }, () => {
        this.updateItemsList();
      });
    }
  }

  setGroupFilter = (group) => {
    const { state: { currentGroupFilter } } = this;
    if (currentGroupFilter.id === group.id) {
      this.setState({ currentGroupFilter: {} }, () => {
        this.updateItemsList();
      });
    } else {
      this.setState({ currentGroupFilter: group }, () => {
        this.updateItemsList();
      });
    }
  }

  resoulveBulkActionBar = () => {
    const { state: { selectedItems } } = this;
    const count = selectedItems.length;
    this.setState({ showBulkActionBar: count > 0, selectedItemsCount: count });
  }

  addItems = () => {
    const { props: { addItemsToPoItemList }, state: { selectedItems } } = this;

    addItemsToPoItemList(selectedItems);
  }

  _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  )

  _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  )

  _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  )

  _renderColls = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="col" key={`${prefix}col_column_${i}`} />
    ))
  )

  render() {
    const {
      state: {
        categoriesFilterOptions, currentCategoryFilter, allBulkActionSelected,
        groupsFilterOptions, currentGroupFilter, headers, supplierItems, isLoading,
        selectedItems,
      },
      props: {
        onToggleSupplierItemModal, diffrentCurrency,
      },
    } = this;

    const dotsNumber = headers.length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div className="modal sub-modal modal-table active">
        <div className="modal-wrapper">
          <div className="modal-window window window-form">
            <div className="window-header modal-header window-header-auto window-header-blue">
              <div className="window-header-wrapper blue">
                <span>
                  <h2>
                    {I18n.t('purchase_orders.form.items.supplier_item_list')}
                  </h2>
                </span>
              </div>
              {
                diffrentCurrency
                  ? (
                    <div className="toast error toast-light">
                      <i className="icon-warning" />
                      {I18n.t('purchase_orders.form.items.diffrent_currency')}
                    </div>
                  ) : null
              }
              <FilterBar
                setCategoryFilter={this.setCategoryFilter}
                categoriesFilterOptions={categoriesFilterOptions}
                currentCategoryFilter={currentCategoryFilter}
                setGroupFilter={this.setGroupFilter}
                groupsFilterOptions={groupsFilterOptions}
                currentGroupFilter={currentGroupFilter}
                onSearchQuery={this.onSearchQuery}
              />
            </div>
            <div className={`window-content supplier-items ${htmlClasses}`}>
              <div className="table-fluid table-column-9">
                <Loader />
                {this._renderInputs(dotsNumber)}
                <div className="table-header with-filters">
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber)}
                    {this._renderDots(dotsNumber)}
                  </div>
                </div>
                <table>
                  <div className="colgroup">
                    {this._renderColls(dotsNumber)}
                  </div>
                  <TableHead
                    headers={headers}
                    onSelectAllBulkAction={this.onSelectAllBulkAction}
                    allBulkActionSelected={allBulkActionSelected}
                    onSort={this.onSort}
                  />
                  <tbody>
                    {
                      supplierItems.map(supplierItem => (
                        <SupplierItemRow
                          key={supplierItem.id}
                          supplierItem={supplierItem}
                          selectedItems={selectedItems}
                          onChangeBulkAction={this.onChangeBulkAction}
                        />
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="window-footer modal-footer">
              <div className="items items-end center">
                <label className="button inverse button-mute hide-on-complete" onClick={onToggleSupplierItemModal}>
                  {I18n.t('commons.actions.cancel')}
                </label>
                <a
                  className=" button button-primary hide-on-complete"
                  onClick={this.addItems}
                >
                  {I18n.t('commons.actions.add_select')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" onClick={onToggleSupplierItemModal} />
      </div>
    );
  }
}

export default SupplierItemsModal;
